@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.location-interest.notification {
  padding-left: 20px;

  @include mobile-and-tablet {
    .mile-text {
      white-space: normal;
      flex: 1 0 0;
      margin-right: 0;
    }

    .location-input {
      flex: 1 0 100%;
      margin-top: 15px;
    }
  }
}

.email-preferences {
  margin: 30px 0;
}
