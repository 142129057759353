@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.location-interest {
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 15px;
  border: 1px solid $border-gray;

  &-box {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  
    .within-text {
      white-space: nowrap;
      margin-right: 10px;
    }
  
    .mile-select {
      flex-basis: 134px;
      margin-right: 10px;
    }
  
    .mile-text {
      white-space: nowrap;
      margin-right: 10px;

      @include mobile {
        white-space: normal;
        flex: 1 0 0;
        margin-right: 0;
      } 
    }
  
    .location-input {
      flex: 1 0 0;

      .address-label {
        font-weight: bold;
      }

      @include mobile {
        flex: 1 0 100%;
        margin-top: 15px;
      } 
    }
  }
}