@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.desktop-navbar {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  height: 60px;
  background-color: $blue1;
  display: none;

  @media (min-width: $desktop-width + 1) {
    display: block;
  }

  @media (max-width: $tablet-width - 1) {
    height: 60px;
  }

  @media (min-width: $desktop-medium-width) and (max-width: $desktop-wide-width) {
    .container {
      padding: 0 90px;
    }
  }
  @media (min-width: $desktop-width) and (max-width: $desktop-medium-width) {
    .container {
      padding: 0 60px;
    }
  }

  .logo {
    width: 110px;
    height: 28px;
    background-image: url("/assets/logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    float: left;
    margin: 16px 40px 16px 0;

    @media (max-width: $tablet-width - 1) {
      width: 64px;
      height: 20px;
      background-size: 64px 20px;
      margin: 19px 0 0;
    }
  }

  &-menu {
    display: inline;
    & > li {
      display: inline-block;
      padding: 10px 0;
      position: relative;
      & > a {
        color: white;
        display: block;
        font-size: 15px;
        font-weight: bold;
        padding: 10px 15px;
        
      }
      .disabled {
        color: rgb(206, 206, 206)
      }
      &.active {
        a {
          background-color: #31639e;
          border-radius: 100px;
        }
      }
    }
  }

  .referral-link {

    a {
      border: 1px solid #4B93E8;
      border-radius: 68px;
      span {
        font-size: 13px;
        font-weight: 500;
        line-height: 23px;
        margin-right: 10px;
        vertical-align: bottom;

      }
      .disabled {
        color: rgb(206, 206, 206)
      }
      em {
        border-left: 1px solid #4B93E8;
        padding-left: 10px;
        font-size: 23px;
        font-style: normal;
        font-weight: bold;
        line-height: 23px;

        &:before {
          content: '$';
          font-size: 11px;
          vertical-align: super
        }
    }
    }
  }

  .profile-picture {
    text-align: center;
    padding: 8px 0;
    width: 33px;
    font-size: 16px;
    line-height: 18px;
  }

  &-menu-right {
    float: right;
    margin: 9px 0;
    & > li {
      display: inline-block;
      margin: 0 0 0 24px;
      position: relative;
      vertical-align: middle;
      & > a {
        color: white;
        display: block;
        font-size: 15px;
        font-weight: bold;
        padding: 20px 0;

        &.tasks-link {
          padding: 5px 0;
          svg {
            fill: #cddcef;
            stroke: #cddcef;
            vertical-align: middle;
          }
        }
      }

      &.referral-link {
        & > a {
          display: flex;
          align-items: center;
          padding: 6px 20px;
        }
      }

      &.referral-link.active, &.referral-link:hover {
        & > a {
          background-color: #31639e;
        }
      }
    }
  }

  &-login {
    float: right;
    vertical-align: middle;
    & > a {
      color: white;
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: bold;
      padding: 20px 0;

      svg {
        stroke: #9abae0;
        margin-left: 7px
      }
    }
  }

}
