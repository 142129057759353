@import "src/ui-kit/_variables.scss";

.warning-message-wrapper {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: $blue5;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1), 0 4px 10px 0 rgba(0,0,0,0.06);
  padding: 15px 20px;

  svg {
    fill: lighten($dark-blue, 5%);
    flex: 0 0 24px;
  }

  .warning-message {
    color: $gray4;
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    margin: 0 0 0 16px;

    .redirect-link {
      color: $blue1;
    }
  }
}
