@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.profile-navbar {
  width: 100vw;
  height: 60px;
  background-color: #F7F8F9;
  border-bottom: 1px solid $border-gray;
  position: fixed;
  top: 60px;
  z-index: 5;

  &-menu {
    white-space: nowrap;
    
    li {
      display: inline-block;
      margin-right: 30px;
      a {
        color: $light-gray;
        display: block;
        font-size: 15px;
        font-weight: bold;
        padding: 20px 0
      }
      &.active {
        a {
          color: $blue1;
          border-bottom: 3px solid $blue1;
          padding-bottom: 18px; /* adjusted for border */
        }
      }
    }

    @include tablet-and-wide-tablet {
      li {
        margin-right: 16px;

        a {
          font-size: 14px;
        }
      }
    }
  }
}
