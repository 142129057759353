@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.lodging-user-requests {
  h5 {
    display: inline-block;
  }
  h6 {
    font-size: 16px;
    margin-bottom: 5px
  }
  a {
    color: $blue1;
    float: right;
    font-size: 14px;
    font-weight: 500;
    max-height: 100%;

    @include desktop {
      font-size: 16px;
    }

    span {
      margin-right: 8px
    }

    svg {
      stroke: $gray6;
      transition: transform 0.2s;
    }

    &.rotated {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .inner {
    padding: 5px 0;
    p.warning {
      color: $salmon;
      font-size: 15px;
      margin-bottom: 20px;
    }
  }

  .not-available {
    display: flex;
    justify-content: space-between;
    color: $gray6;
    font-weight: 500
  }

  .user-requests-section {
    margin-bottom: 20px;

    .symbol {
      font-size: 22px;
      font-weight: 500;
      margin-right: 5px;
    }
    .after-text {
      font-weight: 500;
      margin-left: 5px;
    }

    &:last-child {
      margin-bottom: 0
    }

    .disclaimer {
      line-height: 22px;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 15px;

      &.red {
        color: $salmon;
      }
    }
  }

  .user-requests-lodging {
    .lodging-fields {
      margin-bottom: 10px;

      > div {
        & + div {
          margin-top: .875rem;
        }
      }

      .lodging-gender {
        > label {
          font-size: .9375rem;
          padding: 0 0 .5rem;
          display: block;
        }

        .radio-line {
          display: flex;
          align-items: center;

          .input-box {
            width: 50%;
            border: .125rem solid $gray6;
            border-radius: 4px;
            margin: 0;
            padding: .407rem .875rem;

            & + .input-box {
              margin-left: 1rem;
            }

            &.has-errors {
              border-color: $red;
            }
          }
        }
      }
      
      .lodging-note {
        padding-bottom: .75rem;
      }

      .lodging-teammate {
        width: 100%;

        .invite-lodging-teammate {
          text-align: left;
          padding: .5625rem 0 0;

          a {
            float: none;
            font-size: 1rem;
            font-weight: 500;
          }
        }
      }
    }

    .input-box {
      margin-bottom: 10px;
    }
  }

  .lodging-error {
    &.text {
      line-height: 22px;
      font-size: 15px;
      font-weight: 500;
      margin-top: -5px;
      color: $red;
    }
  }
}
