@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.timesheet-info-status {
  border-radius: 2px;
  display: inline-block;

  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  text-transform: uppercase;
  padding: 1px 4px;

  @include mobile {
    margin-left: auto;
  }

  &.incomplete,
  &.rejected {
    background-color: #f76548;
    border: 1px solid #f76548;
    color: #fff;
  }

  &.paid {
    border: 2px solid $gray6;
    color: $gray5;
  }

  &.partially-paid {
    border: 1px solid #f76548;
    color: #f76548;
  }
  
  &.approved,
  &.submitted {
    border: 2px solid #63be60;
    color: #63be60;
  }

  &.saved {
    border: 2px solid $blue1;
    color: $blue1;
  }

  & + .tooltip-wrapper {
    margin: 0;
    margin-left: .5rem;

    .tooltip-icon-caption {
      svg {
        fill: lighten($dark-blue, 5%);
        transition: ease-in .2s fill;
      }
      
      &:hover {
        svg {
          fill: $dark-blue;
        }
      }
    }
  }
}
