.expenses-list {
  .input-line {
    display: none !important;

    &.show,
    &.closed {
      display: flex !important;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
  .currency {
    .input-box {
      input:not([type="checkbox"]) {
        padding-left: 22px;
      }
      &::after {
        content: "$";
        font-size: 17px;
        position: absolute;
        top: 12px;
        left: 12px;
      }
    }
  }
  .time {
    .input-box {
      // input:not([type="checkbox"]) {
      //   padding-right: 22px;
      // }
      &::after {
        content: "minutes";
        font-size: 17px;
        position: absolute;
        top: 10px;
        right: 12px;
      }
    }
  }
}
