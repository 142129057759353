@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

$dayWidth: 14.2857%;

.vw-cal {
  &-month {
    border-top: 1px solid $border-gray;
    padding: 20px 0;

    h6 {
      font-size: 18px;
      margin-bottom: 5px;
      text-align: center;
    }
  }

  .month-header {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  .month-days {
    display: flex;
    flex-wrap: wrap;
  }

  .week-days {
    display: flex;
    padding: 10px 0;
    .week-day {
      color: $light-gray;
      flex: 0 0 $dayWidth;
      font-size: 12px;
      font-weight: bold;
      line-height: 19px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .event {
    background-color: #f3f8ff;
    color: $blue1;
    font-weight: bold;
    z-index: 10;
  }
}
