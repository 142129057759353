@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.section-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  margin-bottom: 10px;



  @include not-tablet {
    flex-wrap: wrap;
  }

  @include mobile-and-tablet {
    background-color: $gray9;
    padding: 20px;

    &.orange {
      background-color: #FBEDD0;
    }
  }

  &-info {
    flex: 1 1 100%;
    margin-right: 15px;

    @include tablet {
      flex-basis: 60%;
    }

    h3 {
      color: $gray3;
      font-size: 18px;
      font-weight: bold;
      line-height: 25px;
      margin-bottom: 5px;

      @include mobile-and-tablet {
        color: $gray1;
        font-size: 16px;
      }
    }

    p {
      color: #555;
      font-size: 16px;
      font-weight: 400;

      strong {
        display: block;
        margin-top: 5px;
      }

      @include mobile-and-tablet {
        font-size: 15px;
      }
    }
  }

  &-action {
    flex-basis: 100%;

    @include tablet {
      flex-basis: 30%;
    }

    button, .btn {
      font-size: 16px;
      padding: 7px 0;
      width: 100%;

      span,
      svg {
        vertical-align: middle;        
      }
  
      svg.external {
        fill: #fff;
        margin-left: 8px;
      }

      @include tablet {
        width: 190px;
        float: right;
      }

      @include not-tablet {
        margin-top: 20px;
      }

      @include mobile-and-tablet {
        font-size: 15px;
        padding: 5px 0;
      }
    }
  }
}

.disclaimer {
  line-height: 22px;
  font-size: 15px;
  font-weight: 500;
  margin: 16px 0 20px;

  &.red {
    color: #ed4d5d;
  }
}