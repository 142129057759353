@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.confirm-apply-modal {

  .modal-header {
    padding: 30px 0;

    .close {
      position: absolute;
      top: 20px;
      right: 25px;
      z-index: 999;
    
      svg {
        stroke: $gray4;
        width: 20px;
        height: 20px;
        vertical-align: middle
      }
    }
  }

  .modal-body {
    padding: 0 25px 15px;
    text-align: center;

    p {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 30px;
    }
  
    .confirm-actions {
      &>.btn {
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-bottom: 15px;
      }

      @include desktop {
        display: flex;
        justify-content: center;

        &>.btn {
          margin: 0 10px 15px;
          flex: 1;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
   }

  }
}
