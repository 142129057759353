// overrides
.Toastify__toast {
  min-height: 42px;
  overflow: hidden;
  
  &-container {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    min-width: 320px;

    @include desktop {
      width: auto !important;
    }
  }

  &--success {
    background-color: $green2;
    border: 1px solid $green2;
    border-radius: 4px;
    color: #fff;
    padding: 4px 12px;
  }

  &--error {
    background-color: $red;
    border: 1px solid $red;
    border-radius: 4px;
    color: #fff;
    padding: 4px 12px;
  }

  &-body {
    padding-right: 12px;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
}

.Toastify__close-button {
  align-self: auto;
  font-weight: normal;
}
