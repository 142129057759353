@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";
.teammates {
  ul {
    margin-top: 20px;
  }
}

.team-list-header {
  margin-top: 50px;
}
.team-list, .pending-list {
 
  li {
    border-bottom: 2px solid #EEE !important;
    &:first-of-type {
      border-top: 2px solid #EEE;
    }
  }

  .invite-message {
    font-size: 18px;
    padding: 17px;
    @media screen and (max-width: 580px) {
      text-align: center;
    }
  }

  .teammate-item-container {
    font-size: 18px;
    padding: 17px;

    display: flex;
    justify-content: space-between;

    .preview {
      align-self: center;
    }

    @media screen and (max-width: 580px) {
      justify-content: center;
      flex-direction: column;
      .preview {
        margin-bottom: 20px;
      }
    }

    @media screen and (max-width: 580px) {
      .preview { 
        text-align: center;
      }
    }

    .controls {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include mobile {
        justify-content: center;
      }

      .btn {
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0px;
        }
      }
    }



    .invite-month {
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
      color: #757F88;
      text-align: center;
      letter-spacing: 1.1px;
      text-transform: uppercase;
    }

    .invite-day {
      font-weight: bold;
      font-size: 23px;
      line-height: 29px;
      text-align: center;
      color: #353535;
    }

    .teammate-email {
      margin-left: 30px;
      vertical-align: super;
      font-weight: 500;
      font-size: 20px;
      display: inline-block;
    }

    .teammate-status {
      margin-right: 30px;
      font-weight: 500;
      color: #99A1A8;
    }

    .pending-info {
      display: flex;
    }
  }

}

.teammates-info {
  margin-top: 20px;
  margin-left: 17px !important;
}

.nav-tabbed { 
  border-bottom: 2px solid #eee;
  
  .active {
    color: #3877C2;
    border-bottom: 2px solid #3877C2 !important;
  }

  li {
    margin-bottom: -2px;
    border-bottom: 0px !important;
    font-weight: bold;
    display: inline-block;
    padding-bottom: 20px;
    
    &:not(:first-child) {
        margin-left: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.no-margin {
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.team-wrapper, .invite-wrapper {
  margin-top: 50px;
  margin-bottom: 10px;
  p {
    margin: 16px 0px 26px 0px;
  }

  .teammates-form-actions {
    width: 130px;
    margin: 28px auto 0px;
  }
}

.pending-list {
  .team-item {
    .teammate-item-container {
      @include mobile-and-tablet {
        flex-direction: column;
        .pending-info, .controls{
          flex-direction: column;
          .teammate-email {
            margin: 0;
            text-align: center;
          }

          .teammate-status, .btn {
            margin: 0;
          }
        }
      }
    }
  }
}