@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.content {
  &.profile-content {
    background-color: #f7f8f9;
    margin-top: 60px;
    padding: 0;

    @include mobile {
      .container {
        padding: 0;
      }
    }

    @include desktop {
      background-color: white;
      padding: 45px 0;
      margin-top: 120px;
    }

    .profile-form {
      padding-right: 30px;
      @include mobile {
        background-color: white;
        border-top: 1px solid #dee2e3;
        padding: 20px 20px 45px 20px;
      }

      .error-message-wrapper {
        margin-bottom: 20px;
      }
    }

    .mailing-address-form {
      margin-top: 20px;
      h4 {
        margin-bottom: 20px
      }
    }

    .checkbox-line {
      @include desktop {
        margin-top: 5px;
      }
    }

    .form-action {
      margin-top: 35px;

      @include mobile {
        .btn {
          width: 100%
        }
      }
    }
  }
}

.profile-form-column, .gamification-column {
  @include tablet {
    padding-top: 90px;
  }
}
