@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.tasks-list { margin-bottom: 65px }

.task-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  background-color: $gray9;
  border-radius: 4px;
  margin-bottom: 10px;

  @include mobile {
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
  }

  &-info {
    flex: 1 1 60%;
    margin-right: 15px;

    @include mobile {
      flex-basis: 100%;
    }

    h3 {
      color: $gray1;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      margin-bottom: 5px;
    }

    p {
      color: #555;
      font-size: 16px;
      font-weight: 400;

      @include mobile {
        margin-bottom: 20px;
      }
    }
  }

  &-action {
    flex-basis: 30%;

    @include mobile {
      flex-basis: 100%;
    }

    button, .btn {
      font-size: 18px;
      width: 240px;

      @include mobile {
        width: 100%;
      }
    }
  }
}
