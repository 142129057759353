.job-confirm-cancel {
  padding: 0 15px 20px 15px;
  text-align: center;

  .job-confirm-message {
    margin-top: 1rem;
  }

  p {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px
  }
}

.job-confirm-actions {
  display: flex;
  justify-content: center;

  &>.btn {
    margin: 0 10px;
    flex: 1
  }
}
