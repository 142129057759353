@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.scrollbar-container {
  overflow-y: auto;
  padding-bottom: 62px;
  -webkit-overflow-scrolling: touch;

  @include desktop {

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      &:vertical {
        width: 11px;
      }
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: #7d7d7d
    }

    &::-webkit-scrollbar-track {
        background-color: #fafafa;
        border-left: 1px solid $border-gray;
    }
  }
}
