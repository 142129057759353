.counter-badge {
  background-color: #3877C2;
  color: white;
  display: inline-block;
  padding: .25em .4em;
  font-size: 85%;
  padding: 4px 10px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 100px;
  margin-left: 10px;
}

.counter-badge-mob {
  font-size: 100%;
}