@import 'src/ui-kit/_variables.scss';
@import "src/ui-kit/_mixins.scss";

.rating {
  p {
    text-align: center;
    color: #757F88;
    margin-bottom: 20px;
  }

  .rating-stars {
    max-width: 300px;
    margin: 0 auto;
  }

  &-modal {
    padding: 35px 25px 25px;
    text-align: center;
    position: relative;

    @include desktop {
      padding-left: 45px;
      padding-right: 45px;
    }

    .modal-close {
      position: absolute;
      top: 20px;
      right: 20px;

      svg {
        stroke: white;
        height: 20px;
        width: 20px;
        vertical-align: middle;
      }
    }

    .rating-stars {
      width: 90%;
      max-width: 275px;
      margin: 0 auto;
      margin-bottom: 15px;
    }

    .modal-header {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 15px;
      text-align: center;

      @include desktop {
        font-size: 28px;
      }
    }

    .modal-body {
      p.main {
        margin-bottom: 20px;
      }

      .input-line {
        margin: 0 0 20px;
      }

      button {
        font-size: 18px;
      }
    }
  }
}

.rating-stars {
  display: flex;

  & > div {
    flex: 1 1 0;
  }

  svg.star-border {
    fill: lighten(#757F88, 25%);
  }

  svg.star {
    fill: #EEA71C;
  }
}