@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.menu-alert-dot {
  background-color: #f76548;
  border: 3px solid $blue1;
  border-radius: 50%;
  display: inline-block;
  height: 15px;
  position: absolute;
  right: 25px;
  top: 10px;
  width: 15px;

  @include desktop {
    right: 0;
  }
}
