.input-line {
  margin: 15px 0 0;
  @include clearfix;

  @include mobile{
    margin: 12px 0 0;
  }

  &.split-two-line{

    .input-box{
      &:first-child{
        float: left;
      }

      &:nth-child(2){
        float: right;
      }

      &.input-box62{
        width: calc(62.73% - 5px);
      }

      &.input-box50{
        width: calc(50% - 5px);
      }

      &.input-box38{
        width: calc(37.5% - 5px);
      }

      &.input-box34{
        width: calc(34% - 5px);
      }

      &.input-box16{
        width: calc(16% - 5px);
      }
    }

    @include mobile{

      &.not-mobile{

        &.split-two-line{
          .input-box{
            width: 100%;
            float: none;

            &:nth-child(2){
              margin: 12px 0 0;
            }
          }
        }
      }

    }
  }

  &.error{
    input:not([type="checkbox"]):not([type="radio"]),
    textarea{
      border-color: $red;
      color: $red;

      & + label{
        color: $red;
        opacity: 1;
        top: -8px;
      }
    }

  }
}

.input-box-inline {
  display: inline-block;
}


.input-box {
  position: relative;
  text-align: left;

  &-grid {
    margin-bottom: 15px
  }

  &.select-open{
    label{
      z-index: 26;
    }
  }

  &.overlay-input {
    width: 137px;
    height: calc(100% - 4px);
    background-color: #F8F8F8;
    position: absolute;
    top: 2px;
    right: 2px;
    display: flex;
    align-items: center;

    @include mobile {
      width: 107px!important;
      margin: 0!important;
    }

    @include tablet {
      width: 90px;
    }

    input[type="checkbox"],
    input[type="radio"]{

      & + label{
        width: 100%;
        height: 100%;
        font-size: .6875rem;
        line-height: 12px;
        font-weight: 1.1;
        color: #B6BDBF;
        padding: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-left: 2px solid #B6BDBF;
        @include transition(border-color 0.1s, background-color 0.1s, color 0.1s);

        &:before{
          background-color: #FCFCFC;
          border: 2px solid #CDD5D8;
          @include transition(border-color 0.1s, background-color 0.1s);
          margin-left: 12px;
          display: block;
          top: 11px;

          @include mobile{
            position: absolute;
            top: 4px;
            left: 0;
            right: 0;
            margin: auto;

          }
        }

        &:after{
          left: 18px;
          top: 17px;

          @include mobile{
            left: 49px;
            top: 10px;
          }
        }

        span {
          display: block;
          margin-left: 24px;
          position: relative;
          top: 1px;

          @include mobile{
            margin-left: 0;
            position: relative;
            top: 11px;
          }
        }
      }

      &:checked {
        & + label {
          background-color: #E0EAF7;
          border-color: $dark-blue;
          color: $dark-blue;

          &:before {
            background-color: #FFFFFF;
            border-color: $dark-blue;
          }
        }
      }
    }
  }

  &.overlay-input-vertical {
    width: 74px;
    height: calc(100% - 4px);
    background-color: #F8F8F8;
    position: absolute;
    top: 2px;
    right: 2px;
    display: flex;
    align-items: center;

    @include mobile{
      width: 107px !important;
      margin: 0;
    }

    input[type="checkbox"],
    input[type="radio"]{

      & + label{
        width: 100%;
        height: 100%;
        font-size: 11px;
        line-height: 12px;
        font-weight: 500;
        color: #B6BDBF;
        padding: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-left: 2px solid #B6BDBF;
        @include transition(border-color 0.1s, background-color 0.1s, color 0.1s);

        &:before{
          background-color: #FCFCFC;
          border: 2px solid #CDD5D8;
          @include transition(border-color 0.1s, background-color 0.1s);
          position: static;
          margin: 0 auto 5px;
          display: block;

          @include mobile{
            position: absolute;
            top: 4px;
            left: 0;
            right: 0;
            margin: auto;

          }
        }

        &:after{
          top: 10px;
          left: 0;
          right: 0;
          margin: auto;

          @include mobile{
            top: 10px;
          }
        }

        span{
          display: block;
          line-height: 12px;

          @include mobile{
            position: relative;
            top: 11px;
          }
        }
      }

      &:checked{
        & + label{
          background-color: #E0EAF7;
          border-color: #5B85BE;
          color: $dark-blue;

          &:before{
            background-color: #FFFFFF;
            border-color: $dark-blue;
          }
        }
      }
    }
  }

  &.has-text{
    input[type=radio]:checked,
    input[type=checkbox]:checked{
      & + label{
        border-color: #8B9699;
      }
    }
  }

  input:not([type="checkbox"]):not([type="radio"]),
  .Select,
  textarea {
    & + label {
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: $light-gray;
      background-color: #fff;
      padding: 0 5px 0 4px;
      position: absolute;
      top: -4px;
      left: 13px;
      right: auto;
      max-width: calc(100% - 26px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      pointer-events: none;
      opacity: 0;
      @include transition(top 0.1s, opacity 0.1s);

      @include mobile{
        font-size: 14px;
        line-height: 17px;
        padding: 0 4px 0 3px;
        top: -4px;
        left: 12px;
      }

      & + .svg {
        fill: $gray6;
        position: absolute;
        right: 16px;
        top: 14px;
      }
    }
  }

  .info-text {

    @include mobile{
      font-size: 12px;
      line-height: 15px;
      font-weight: 500;
      color: #82898B;
      float: left;
      margin: 5px 0 0;
    }
  }
}

input{
  -webkit-appearance: none;
}

input:not([type="checkbox"]):not([type="radio"]),
textarea {
  width: 100%;
  font-weight: 500;
  font-size: 1.0625rem;
  line-height: 1.24;
  max-height: 45px;
  font-family: 'Macha', 'Arial', 'Helvetica', 'sans-serif';
  color: #4D4F51;
  padding: 10px 12px;
  border: 2px solid #B6BDBF;
  box-shadow: none;
  outline: none;
  @include border-radius(4px);
  @include transition(border-color 0.1s, box-shadow 0.1s);
  resize: none;
  display: block;
  -webkit-appearance: none;

  .height-auto & {
    max-height: inherit;
  }

  &:disabled {
    background-color: $gray9;
    -webkit-text-fill-color: $input-disabled-color;
  }

  @include placeholder {
    color: $gray6;

    @include mobile{
      color: #a1a8aa;
    }
  }

  &.placeholder-small {
    @include mobile {
      @include placeholder {
        font-size: 14px
      }
    }
  }

  @include mobile{
    font-size: 18px;
    line-height: 22px;
    padding: 11px 14px 10px;
  }

  &:hover{
    border-color: #9EA4A6;
  }

  &.has-text{
    border-color: #8B9699;

    & + label{
      color: #82898B;
      opacity: 1;
      top: -8px;
    }

    &:disabled {
      & + label{
        color: $light-gray;
      }
    }
  }

  &:focus{
    border-color: #5B85BE;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,0.05);

    & + label{
      color: #5B85BE;
      opacity: 1;
      top: -8px;
    }
  }

  &.has-errors {
    border-color: $red;

    & + label {
      color: $red;
    }
  }
}

textarea{
  -webkit-padding-start: 11px;
  max-height: initial;
}

input[type="checkbox"],
input[type="radio"]{
  float: left;
  opacity: 0;
  width: 0;
  height: 0;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: 0;

  & + label{
    font-size: 15px;
    font-weight: 500;
    line-height: 28px;
    padding: 0 0 0 32px;
    cursor: pointer;
    position: relative;
    display: block;

    &.has-errors {
      color: $red;

      &:before {
        border-color: $red;
      }
    }

    svg {
      margin-right: 8px;
      position: relative;
      top: 1px
    }

    @include mobile{
      font-size: 16px;
      line-height: 18px;
      font-weight: 500;
      padding: 0 0 0 28px;
    }
  }
}

input[type="radio"]{
  & + label{
    @include mobile{
      padding: 0 0 0 31px;
    }
  }
}

input[type="checkbox"]{
  @include transition(border-color 0.1s);

  & + label{
    &:before{
      content: '';
      width: 20px;
      height: 20px;
      border: 2px solid #B6BEBF;
      @include border-radius(3px);
      @include transition(border-color 0.1s);
      position: absolute;
      top: 4px;
      left: 0;

      @include mobile{
        top: -2px;
      }
    }

    &:after{
      content: '';
      width: 12px;
      height: 10px;
      background-image: url("/assets/icon-tick.svg");
      position: absolute;
      top: 9px;
      left: 4px;
      @include transition(opacity 0.1s);
      opacity: 0;

      @include mobile{
        top: 3px;
      }
    }
  }

  &:hover{
    & + label{
      &:before{
        border-color: #858B8C;
      }
    }
  }

  &:checked{
    & + label{
      &:before{
        border-color: $dark-blue;
        background-color: $dark-blue;
      }

      &:after{
        opacity: 1;
      }
    }
  }

  &:disabled {
    & + label {
      background-color: #fff;
      color: $light-gray;
      cursor: default;
      font-weight: 500;

      &:before {
        background-color: #fff;
        border-color: #B1BAC3;
      }

      &:after {
        background-image: url("/assets/icon-tick-disabled.svg");
      }
    }
  }
}

input[type="radio"]{

  & + label{

    &:before{
      content: '';
      width: 20px;
      height: 20px;
      border: 2px solid #B6BEBF;
      @include border-radius(50%);
      @include transition(border-color 0.1s);
      position: absolute;
      top: 4px;
      left: 0;

      @include mobile{
        top: 4px;
      }
    }

    &:after{
      content: '';
      width: 8px;
      height: 8px;
      background-color: #5B85BE;
      @include border-radius(50%);
      position: absolute;
      top: 10px;
      left: 6px;
      @include transition(opacity 0.1s);
      opacity: 0;

      @include mobile{
        top: 10px;
      }
    }
  }

  &:hover{
    & + label{
      &:before{
        border-color: #858B8C;
      }
    }
  }

  &:checked{
    & + label{
      &:before{
        border-color: $dark-blue;
      }

      &:after{
        opacity: 1;
      }
    }
  }
}

select{
  width: 100%;
  outline: none;

  @include mobile{
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.input-line, .radio-line {
  label {
    span {
      line-height: 28px
    }
  }
}

.textarea-counter{
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #B6BDBF;
  float: right;
  margin: 5px 0 0;
}

.go-back-link {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: #9da4a6;
  display: inline-block;
  margin: 19px 0 0;
  transition: color 0.1s
}
