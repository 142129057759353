@import "src/ui-kit/_variables.scss";

.help-center-icon {
  background-color: #fff;
  border-radius: 50%;
  color: $blue1;
  cursor: pointer;
  display: flex;
  font-family: "Helvetica";
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  height: 32px;
  width: 32px;
}

.help-center-menu {
  margin-top: 13px;
  left: -73px;
  width: 180px;

  .help-center-links {
    border-bottom: 1px solid #dee2e3;
    padding: 8px 0;

    a {
      color: $black-light;
      display: block;
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      padding: 0 15px;
      span {
        display: inline-block
      }
    }

    a:hover {
      background-color: $blue1;
      color: white;
    }
  }
}
