@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.editable-field {
  &:first-of-type {
    border-top: none;
  }
  border-top: 2px solid #EEE;
  font-size: 18px;
  line-height: 30px;
  padding: 19px 0;
  .btn {
    width: 100px;

    @include mobile {
      font-size: 13px;
      padding: 8px 16px;
      width: auto;
    }
  }

  .label-mobile {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;

    @include desktop {
      display: none
    }
  }

  .form-mode {
    display: flex;
    &.hidden {
      display: none;
    }
    .input-box {
      flex-grow: 1;
      margin-right: 40px;
    }
    .controls {
      flex-basis: 100px;
      @include mobile {
        flex-basis: 60px;
        text-align: center;
      }
      .cancel-link {
        display: block;
        font-size: 15px;
        line-height: 15px;
        padding: 15px 0;
        text-align: center;
      }
    }
  }
  .preview-mode {
    &.hidden {
      display: none;
    }
    display: flex;
    align-items: center;
    max-width: 100%;
    .label {
      display: none;
      flex-grow: 0;
      font-weight: bold;
      margin-right: 40px;
      padding: 10px 0;

      @include desktop {
        display: block;
        flex-basis: 100px;
      }
    }
    .preview {
      display: inline-block;
      flex: 1;
      margin-right: 40px;
      padding: 10px 0;
      word-break: break-all;
    }
    .controls {
      flex-basis: 100px;
      @include mobile {
        flex-basis: 60px;
        text-align: center;
      }
    }
  }
}
