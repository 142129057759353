@font-face {
  font-family: 'Macha';
  src: url("/assets/fonts/macha_medium-webfont.woff2") format("woff2");
  font-weight: 500;
  font-style: normal; }
@font-face {
  font-family: 'Macha';
  src: url("/assets/fonts/macha_bold-webfont.woff2") format("woff2");
  font-weight: 700;
  font-style: normal; }
@font-face {
  font-family: 'Macha';
  src: url("/assets/fonts/macha_bolditalic-webfont.woff2") format("woff2");
  font-weight: 700;
  font-style: italic; }
@font-face {
  font-family: 'Macha';
  src: url("/assets/fonts/macha_book-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'Macha';
  src: url("/assets/fonts/macha_bookitalic-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: italic; }
@font-face {
  font-family: 'Macha';
  src: url("/assets/fonts/macha_mediumitalic-webfont.woff2") format("woff2");
  font-weight: 500;
  font-style: italic; }
@font-face {
  font-family: 'Macha';
  src: url("/assets/fonts/macha_thin-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: normal; }
@font-face {
  font-family: 'Macha';
  src: url("/assets/fonts/macha_thinitalic-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: italic; }
