@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.drug-screen-modal {
  .modal-content {
    padding: 25px 15px;
    color: white !important;
    background-color: $red;

    @include desktop {
      padding:  25px 20px;
    }
  }

  .modal-header {
    margin-bottom: 10px;
    width: 100%;
    text-align: center;

    h3 {
      font-size: 28px;
    }

    .modal-close {
      float: right;

      svg {
        stroke: white;
        height: 20px;
        width: 20px;
        vertical-align: middle;
      }
    }
  }

  .modal-body {
    margin: 15px 0;
    text-align: center;

    p {
      font-size: 18px;
      line-height: 26px;
      margin: 10px 0;
    }

    a {
      color: white;
      text-decoration: underline;
    }
  }

  .modal-footer {
    text-align: center;

    button {
      color: white;
      background-color: $light-red;
      width: 175px;
    }
  }
}
