@import "src/ui-kit/_variables.scss";

.side-menu-wrap {
  top: 0;

  .bm-cross {
    background: $gray5;
  }
}

.side-menu {
  background-color: #333;
  color: white;
  width: 350px;
  max-width: 100%;

  &-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-header {
    border-bottom: 1px solid $gray2;
    padding: 25px 30px;

    .associate {
      display: flex;

      .profile-picture {
        text-align: center;
        height: 60px;
        width: 60px;
        font-size: 24px;
        line-height: 60px;
        margin-right: 18px;
        min-width: 60px;
      }

      &-name {
        h5 {
          font-size: 20px;
          font-weight: 500;
          line-height: 25px;
          margin-bottom: 7px;
        }

        h6{
          color: $gray4;
          font-size: 12px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }

        .associate-id {
          font-size: 18px;
          font-weight: bold;
          line-height: 22px;
          padding: 2px 0;
          vertical-align: middle;
        }
      }
    }
  }

  &-body,
  &-help {
    border-bottom: 1px solid $gray2;
    flex: 1;
    padding: 25px 30px;

    ul {
      &>li {
        margin-bottom: 25px;
        a {
          display: flex;
          svg {
            flex-basis: 24px;
            fill: $gray5;
            margin-right: 12px
          }
          span {
            color: $gray5;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
          }
        }
        .disabled {
          span {
            color: rgb(110, 110, 110)
          }
        }
      }
    }

    .referral-link {

      a {
        background-color: $blue1;
        border-radius: 68px;
        color: white;
        display: inline-block;
        padding: 9px 30px;

        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 26px;
          margin-right: 14px;
          vertical-align: bottom;
        }

        em {
          border-left: 1px solid #4B93E8;
          padding-left: 14px;
          font-size: 24px;
          font-style: normal;
          font-weight: bold;
          line-height: 24px;

          &:before {
            content: '$';
            font-size: 11px;
            vertical-align: super
          }
        }
      }

      .disabled {
        background-color: #3e3e3e;
        span, em {
          color: rgb(110, 110, 110)
        }
        em {
          border-left: 1px solid #595959;
        }
      }
    }
  }

  &-help {
    li:last-child {
      margin-bottom: 0
    }
  }

  &-footer {
    padding: 16px 30px;

    .logout {
      span {
        color: $gray8;
        font-size: 16px;
        line-height: 20px;
        vertical-align: middle;
      }
      svg {
        margin-left: 5px;
        stroke: $gray8;
        vertical-align: middle;
      }
    }
  }
}
