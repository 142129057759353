@import "src/ui-kit/_variables.scss";

.profile-menu {
  margin-top: 13px;
  left: -73px;
  width: 180px;

  .associate {
    display: flex;
    border-bottom: 1px solid #dee2e3;
    padding: 15px 10px;

    .profile-picture {
      float: left;
      margin-right: 8px;
      padding: 11px 0;
      flex: 0 0 38px;
      height: 38px;
      width: 38px;
    }
    &-name {
      h6 {
        color: $black-light;
        font-size: 15px;
        font-weight: bold;
        line-height: 1.1;
        word-break: break-word;
      }

      .associate-id {
        border: 1px solid #d3d6d7;
        border-radius: 100px;
        color: #757f88;
        display: inline-block;
        font-size: 11px;
        font-weight: bold;
        height: 17px;
        line-height: 12px;
        padding: 2px 5px;
        vertical-align: middle;
      }
    }
  }

  .profile-links {
    border-bottom: 1px solid #dee2e3;
    padding: 8px 0;

    a {
      color: $black-light;
      display: block;
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      padding: 0 15px
    }

    .disabled {
      color: rgb(206, 206, 206)
    }

    a:hover {
      background-color: $blue1;
      color: white;
    }
  }

  .logout {
    color: #757F88;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 26px;
    padding: 8px 15px;
    text-transform: uppercase;
    cursor: pointer;

    svg {
      stroke: $light-gray;
      height: 7px;
      width: 9px;
    }
  }
}
