@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.refresh-message {
  padding: 25px;
  text-align: center;

  p {
    margin-bottom: 20px;

    strong {
      font-size: 18px;
    }
  }

  button {
    padding-right: 25px;
    padding-left: 25px;
  }
}
