@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.side-modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity .15s linear;

  &-backdrop {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    transition: 300ms ease all;

    &.open {
      background-color: rgba(51, 51, 51, 0.5);
    }

    &.closing {
      background-color: transparent
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    width: 100%;
    transition: 300ms ease all;
    transform: translate3d(100%, 0, 0);
    z-index: 1000;

    @include desktop {
      background-color: $gray9;
      width: 620px;
    }

    &.open {
      box-shadow: 0 2px 25px 0 rgba(0,0,0,0.5);
      transform: translate3d(0, 0, 0);
    }

    // Signature orientation lock
    // 
    // @media screen and (max-width: 767px) and (orientation: portrait) {
    //   &.landscape {
    //     transform: rotate(-90deg);
    //     transform-origin: left top;
    //     width: 100vh;
    //     height: 100vw;
    //     overflow-x: hidden;
    //     position: absolute;
    //     top: 100%;
    //     left: 0;
    //   }
    // }

    // @media screen and (max-width: 767px) and (orientation: landscape) {
    //   &.landscape {
    //     overflow-x: auto;
    //     transform: rotate(90deg);
    //     width: 100vw;
    //   }
    // }
  }

  &-header {
    padding: 0;

    @include desktop {
      padding: 0 35px;
    }
  }

  &-body {
    padding: 0 15px;;

    @include desktop {
      display: flex;
      flex-direction: column;
      flex: 1;
      font-size: 18px;
      padding: 0 35px;
      overflow-y: scroll;
    }

    .body-section {
      padding: 20px 0;
      width: 100%;

      @include desktop {
        border-radius: 3px;
        margin-top: 20px;
        padding: 20px 25px;
        background-color: white;
        border: 0;
        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.08);

        &:last-child {
          margin-bottom: 20px;
        }
      }

      .section-header {
        margin-bottom: 20px;

        &-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 5px;
        }

        .warning {
          color: #ed4d5d;
          font-size: 15px;
        }

        span {
          color: #ed4d5d;
          font-size: 14px;
          font-weight: bold;
        }
        .block {
          display: inline-block;
          border: 3px solid #ed4d5d;
          border-radius: 2px;
          height: 12px;
          width: 12px;
          margin-right: 8px;
        }
      }

      h5 {
        font-size: 16px;

        @include desktop {
          font-size: 18px;
          line-height: 30px;
        }
      }

      svg {
        fill: $gray6;
        margin-right: 5px;

        @include desktop {
          margin-right: 8px;
        }
      }
    }
  }

  &-footer {
    border-top: 1px solid $gray8;
    padding: 20px 20px;
    margin-top: -90px;
    width: 100%;
    z-index: 3;
    background-color: white;
    @include desktop {
      background-color: white;
      border: 0;
      box-shadow: 0 -1px 2px 0 rgba(0,0,0,0.08);
      padding: 1.6rem 2.3rem;
    }

    position: sticky;
  }

  &-close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999;

    @include desktop {
      top: 35px;
      right: 35px;
    }

    svg {
      stroke: $gray4;
      vertical-align: middle
    }
  }
}
