@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.file-modal {
  .modal-content {
    align-items: flex-start;
    display: flex;
    padding: 25px 15px;

    @include desktop {
      width: 65vw;
      max-width: 1024px;
      padding:  60px 60px 20px;
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      width: 100%;

      h3 {
        width: 100%;
        overflow:hidden; 
        white-space:nowrap; 
        text-overflow: ellipsis;
      }

      .modal-close {
        margin-left: 1rem;
        flex-shrink: 0;
      }
    }

    .pdf-container {
      height: 80vh;
      margin: 10px 0 20px;
      width: 100%;

      iframe {
        height: 100%;
        width: 100%;
      }
    }

    .modal-body {
      margin: 15px 0;

      p {
        font-size: 18px;
        line-height: 26px;
        margin: 10px 0;
        text-align: center;

        small {
          font-size: 14px;
          font-weight: 300;
        }
      }
    }

    .modal-footer {
      align-self: center;

      button {
        width: 275px;
        margin-bottom: 10px;
      }

      p {
        line-height: 26px;
        margin-bottom: 10px;
        text-align: center;

        small {
          font-size: 14px;
          font-weight: 300;
        }
      }
    }
  }

  &.alert {
    .modal-footer {
      button {
        margin-bottom: 0;
      }
    }
  }
}