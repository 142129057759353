@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.job-detail-cancel {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 35px 0;

  p {
    color: $gray4;
    font-size: 16px;
    line-height: 22px;
    width: 100%;

    .center {
      text-align: center;
    }
  }

  &.center {
    text-align: center;
  }

  .btn {
    flex-shrink: 0;
    &.btn-green {
      height: 55px;
      padding-left: 38px;
      padding-right: 38px;
    }
    margin-left: 48px;
  }

  @include mobile {
    flex-wrap: wrap;
    margin: 0;
    .btn {
      margin: 0;
      order: 1;
      width: 100%;
    }
    p {
      display: block;
      height: 100%;
      margin: 20px 0;
      order: 2;
      text-align: center;
    }
  }
}
