@import "src/ui-kit/_variables.scss";

body {
  min-height: 100%;
  font-family: 'Macha', Arial, Helvetica, sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: #4D4F51;
  background-color: $gray10;
  width: 100%;
  overflow-x: hidden;

  &.menu-open {
    max-height: 100vh;
    overflow: auto;
    position: fixed;
  }

  &.modal-open {
    height: 100%;
    overflow: hidden;
  }

  .instructions-link {
    a {
      float: none;
    }
  }
}

strong { font-weight: bold }

.container {
  max-width: 1170px;
  padding: 0 15px;
  margin: 0 auto;
  @include clearfix;
}

main {
  flex: 1;
  padding-top: $navbar-height;
  @include clearfix;

  &.authentication {
    @include mobile {
      padding-top: 40px;
    }
  }
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  h2 { margin: 0 }

  .external-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #cddcef;
    border-radius: 3px;
    background-color: #f3f8ff;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.06);
    color: $blue1;
    font-size: 13px;
    font-weight: 500;
    padding: 7px 15px;
    width: 164px;

    svg { fill: $blue1 }
  }
}

.page-body {
  .container {
    @include mobile {
      padding: 0
    }
  }
}

.mobile-compressed {
  @include mobile {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.mobile-only{
  @media (max-width: $tablet-width - 1) {
    display: block;
  }
}

.mobile-hide{
  @media (max-width: $tablet-width - 1) {
    display: none;
  }
}

.btn-edit {
  &:hover {
    background-color: #80868b;
  }

  &:active {
    background-color: #6f7479;
  }
}

.btn-green {
  &:active {
    background-color: #4c9249;
  }
}

:focus {outline:none;}
::-moz-focus-inner {border:0;}
