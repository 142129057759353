@import "src/ui-kit/_variables.scss";

.sort-icon {
  cursor: pointer;
  display: inline-block;
  position: relative;

  &.active {
    svg {
      &:first-child {
        fill: $blue1
      }
    }
  }

  &:hover{
    .tooltip {
      display: block;
    }
  }

  &.disabled {
    cursor: default;
  }

  svg {
    fill: #c9cfd0;

    &.sort-caret {
      stroke: #c9cfD0;
      margin-left: 5px;
    }

    &:first-child {
      vertical-align: middle
    }
  }

  .tooltip {
    width: 198px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    background-color: #4A4A4A;
    border-radius: 4px;
    padding: 10px;
    display: none;
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    margin: 0 0 7px -90px;
    z-index: 2;

    strong {
      display: block;
      margin-bottom: 2px;
    }

    &:after{
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #4A4A4A transparent transparent transparent;
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

.sort-caret {
  transition: transform 0.3s ease;
  &.up {
    transform: rotate(180deg);
  }
}
