@mixin transform {
  transform: translate3d(0, 0, 0);
}

@keyframes expand {
  100% {
    overflow: inherit;
  }
}

@keyframes slideDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    @include transform;
  }
}

@keyframes slideUp {
  from {
    @include transform;
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -110%, 0);
  }
}

.expandable {
  max-height: 0;
  overflow: hidden;
  position: relative;
  transition-duration: 0.3s;
  transition-property: max-height;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  &.expanded {
    max-height: 10000px;
    transition: all 0.3s ease-in;
    animation-name: expand;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

.Hireflex__slide-enter {
  animation-name: slideDown;
}
.Hireflex__slide-exit {
  animation-name: slideUp;
}
