@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.job-detail-header {
  padding: 15px 15px 10px 15px;
  width: 90%;

  @include desktop {
    padding: 35px 0 10px 0;
  }

  .job-detail-title {

    h1 {
      color: $blue1;
      display: inline-block;
      font-size: 20px;
      line-height: 26px;
      font-weight: bold;
      margin-right: 10px;

      @include desktop {
        font-size: 28px;
        line-height: 30px;
      }
    }

    h3 {
      font-size: 15px;
      line-height: 22px;
      font-weight: 500;
    }

    svg {
      margin: 0 5px
    }
  }

  .job-id {
    color: $gray6;
    font-size: 14px;
    font-weight: 500
  }
}
