@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.disclosures-list {
  background-color: white;
  overflow: hidden;

  @include tablet {
    border: 1px solid $gray7;
    border-radius: 8px;
    margin-bottom: 25px;
  }
}