@import "src/ui-kit/_mixins.scss";
@import "src/ui-kit/_variables.scss";

.task-instructions {

  .info-message {
    margin-bottom: 20px;
  }

  .modal-content {

    .task-instructions-modal {
      padding: 20px;

      .modal-header {
        h3 {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 5px;
        }
        margin: 0 0 20px 0;
        text-align: center;
      }

      .modal-body{

        .main {
          margin-bottom: 30px;
          @include mobile {
            margin-bottom: 20px;
          }
        }

        ol {
          list-style-type: decimal;
          margin-left: 20px;
          li {
            margin-bottom: 10px;
          }
        }

        button {
          display: block;
          margin: 10px 0;
          width: 100%;
        }

        a {
          color: $gray4;
          margin-bottom: 20px;
          text-decoration: underline;
        }
      }

      a.modal-close {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
}
