@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.timesheets-content {
  .alert-link {
    color: white;
    text-decoration: underline;
  }
  .ReactTable {
    border: none;
    min-height: 250px;

    a {
      background-color: #63be60;
      border-radius: 4px;
      color: white;
      font-weight: bold;
      padding: 4px 8px;

      &.submitted {
        background-color: #838383;
      }
      &.submitted:hover {
        background-color: #737373;
      }
      &.rejected {
        background-color: #be6360;
      }
      &.rejected:hover {
        background-color: #ae5350;
      }
      &:hover {
        background-color: #53ae50;
        text-decoration: none;
      }
    }
    .rt-table {
      margin-bottom: 20px;
    }

    .rt-noData {
      top: 85px;
      white-space: pre;
      background-color: $gray10;
    }

    .-loading {
      min-height: 250px;

      & > div {
        top: 60px;
        position: inherit;
        transition: none;
        transform: none;
      }

      &.-active {
        & > div {
          transform: none;
        }
      }
    }

    .rt-thead {
      @include mobile {
        display: none;
      }

      &.-header {
        background-color: #f7f8f9;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-weight: bold;
        box-shadow: none;
      }

      .rt-th {
        border: none;
        box-shadow: none;
        padding: 13px 21px;

        &.-sort-asc {
          box-shadow: none;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .rt-tbody {
      @include mobile {
        min-width: auto !important;
      }

      .rt-tr-group {
        border-bottom: solid 1px #ddd;

        @include mobile {
          &:first-child {
            border-top: solid 1px #ddd;
          }
        }
      }

      .rt-tr {
        cursor: pointer;

        &.incomplete {
          background-color: #fff8f5;
          .job-info-project {
            color: #f76548;
          }
          &.active {
            @include desktop {
              background-color: #fff8f5;
              z-index: 300;
            }
          }
        }

        &.paid {
          .vertical-date > .day,
          .job-info-project,
          .job-info-meta > strong,
          .job-info-numbers > .value,
          .job-info-numbers > .dollar-amount {
            color: $gray5;
          }
          .vertical-date > .month,
          .job-info-meta > span,
          .job-info-numbers > .header {
            color: $gray6;
          }
        }

        &.active {
          @include desktop {
            background-color: #fff;
            z-index: 300;
          }
        }
      }

      .rt-td {
        padding: 8px 11px 5px 11px;
        white-space: inherit;

        &.job-column-project {
          overflow: visible;
        }

        @include desktop {
          padding: 17px 21px 13px 21px;
          white-space: nowrap;
        }
      }
    }

    .job-column {
      &-date {
        flex: 0 0 80px;
        @include mobile {
          flex: 1 0 15% !important;
          text-align: center;
        }
      }
      &-project {
        text-align: left;
        @include mobile {
          flex: 1 0 85% !important;
        }
      }
      &-numbers {
        text-align: center;
        @include mobile {
          display: none;
        }
      }
    }

    .job-info {
      &-mobile {
        align-items: center;
        display: flex;
        font-size: 11px;
        font-weight: 500;
        margin: 5px 0;
        div {
          margin-right: 15px;
        }
        .dollar-amount {
          font-size: 11px;
        }
        .value,
        .dollar-amount {
          margin-right: 4px;
        }
        @include tablet {
          display: none;
        }
        @include desktop {
          display: none;
        }
      }

      &-meta {
        color: #99a1a8;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;

        strong {
          color: #4e555c;
          font-weight: 500;
          margin-right: 10px;
        }
      }
      &-title {
        display: flex;
        align-items: center;
        @include mobile {
          justify-content: space-between;
        }
      }
      &-project {
        color: $blue1;
        font-size: 20px;
        font-weight: 500;
        margin-right: 20px;
        width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &-numbers {
        .header {
          display: block;
          color: #757f88;
          font-size: 11px;
          font-weight: 500;
          line-height: 13px;
          text-align: center;
        }
        .value {
          display: block;
          color: #333;
          font-size: 13px;
          font-weight: 500;
          line-height: 23px;
          text-align: center;

          @include desktop {
            font-size: 23px;
            line-height: 29px;
          }
        }
      }
    }
  }
}

.page-header {
  margin-top: 2rem;
}
