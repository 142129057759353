@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.job-detail-location {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-bottom: 20px;

  svg {
    fill: $gray6;
    margin-right: 10px;
    @include mobile {
      margin: 2px 10px auto 0;
    }
  }

  .location-strong {
    color: $gray1;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-right: 10px;
  }

  .location-muted {
    @include mobile {
      display: block;
    }
    color: $gray6;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
  }
}
