@import 'src/ui-kit/_variables.scss';
@import "src/ui-kit/_mixins.scss";

.support {
  p {
    text-align: center;
    color: #757F88;
    margin-bottom: 20px;
  }

  button {
    font-size: 18px;
  }

  &-modal {
    padding: 35px 25px 25px;
    text-align: center;
    position: relative;

    @include desktop {
      padding-left: 45px;
      padding-right: 45px;
    }

    .modal-close {
      position: absolute;
      top: 20px;
      right: 20px;

      svg {
        stroke: white;
        height: 20px;
        width: 20px;
        vertical-align: middle;
      }
    }

    .modal-header {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;

      @include desktop {
        font-size: 28px;
      }
    }

    .modal-body {
      .input-line {
        margin: 0 0 20px;
      }

      button {
        font-size: 18px;
      }
    }
  }
}