// Mixins
@mixin svg-color($SvgColor){
    &.stroke{
        stroke: $SvgColor;
    }
    &.fill{
        fill: $SvgColor;
    }
}

@mixin clearfix {
    &:before,
    &:after {
        content: " ";
        display: table;
        clear: both;
    }
}

@mixin box-shadow-border($width, $color) {
    box-shadow: $width 0 0 0 $color, 
        0 $width 0 0 $color, 
        $width $width 0 0 $color,
        $width 0 0 0 $color inset, 
        0 $width 0 0 $color inset;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}

@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin transform($args...) {
    -webkit-transform: $args;
    -moz-transform: $args;
    -ms-transform: $args;
    -o-transform: $args;
    transform: $args;
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin tablet-and-wide-tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$tablet-wide-width + 1px}) {
      @content;
  }
}

@mixin tablet-wide-and-desktop {
  @media (min-width: #{$tablet-wide-width + 1px}) and (max-width: #{$desktop-width - 1px}) {
      @content;
  }
}

@mixin mobile-and-tablet {
    @media (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin desktop-wide {
    @media (min-width: #{$desktop-wide-width}) {
        @content;
    }
}

@mixin not-mobile {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin not-tablet {
    @media (max-width: #{$tablet-width - 1px}), (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin not-mobile-and-tablet {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin desktop-until-desktop-medium {
  @media (min-width: #{$desktop-width}) and (max-width: #{$desktop-medium-width - 1px}) {
    @content;
  }
}

@mixin not-desktop-wide {
    @media (max-width: #{$desktop-wide-width - 1px}) {
        @content;
    }
}

@mixin retina {
    @media only screen and (-Webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (min-device-pixel-ratio: 1.5) {
        @content;
    }
}
