@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.employment-information {
  .video-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    width: 100%;
    margin: 10px 0 40px;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      border: 0;
      width: 100%;
      height: 100%;
    }
  }

  h3 {
    &:first-of-type {
      margin-top: 25px;
      @include desktop {
        margin-top: 30px;
      }
    }

    margin-top: 16px;

    .tooltip-wrapper {
      margin: 5px 0 0 7px;
    }

    @include tablet{
      font-size: 20px;
      line-height: 25px;

      .tooltip-wrapper {
        margin-top: 4px;
      }
    }
  
    @include mobile{
      font-size: 18px;
      line-height: 20px;

      .tooltip-wrapper {
        margin-top: 3px;
      }
    }
  }
  
  ul {
    li {
      padding: 20px 0;
      border-bottom: 2px solid #eee;

      &:last-child {
        border-bottom: none;
      }

      a {
        color: $gray3;
        display: flex;
        align-items: center;

        h4 {
          width: 80%;
          margin-bottom: 0;
        }

        svg.external {
          fill: $gray3;
          margin-left: 10px;
        }

        svg {
          margin-right: 35px;

          &.alert { fill: $red; }
          &.tick { fill: $green1; }
          &.info { fill: $gray6; }
        }

        .status-label {
          font-size: 16px;
          font-weight: 500;
          line-height: 23px;
          text-align: right;
          text-transform: capitalize;
          width: 80px;

          &.complete { color: $green1; }
          &.incomplete { color: $red; }
          &.pending, &.unavailable, &.loading { color: $gray6; }

        }
      }
    }
  }

  .modal {

    &.external-link,
    &.alert {
      .modal-content {
        @include desktop {
          padding: 20px;
          width: 550px;
        }

        .modal-header {
          display: flex;
          flex-direction: column;

          h3 {
            font-size: 28px;
            line-height: 30px;
            margin-top: 10px;
            text-align: center;
          }

          .modal-close {
            align-self: flex-end;
            flex: 1;
          }
        }

        .modal-body {
          padding: 0 30px;
        }
      }
    }

    .modal-content {
      align-items: flex-start;
      display: flex;
      padding: 25px 15px;

      @include desktop {
        width: 65vw;
        max-width: 1024px;
        padding:  60px 60px 20px;
      }

      .modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;
      }

      .pdf-container {
        height: 80vh;
        margin: 10px 0 20px;
        width: 100%;

        iframe {
          height: 100%;
          width: 100%;
        }
      }

      .modal-body {
        margin: 15px 0;

        p {
          font-size: 18px;
          line-height: 26px;
          margin: 10px 0;
          text-align: center;

          small {
            font-size: 14px;
            font-weight: 300;
          }
        }
      }

      .modal-footer {
        align-self: center;

        button {
          width: 275px;
          margin-bottom: 10px;
        }

        p {
          line-height: 26px;
          margin-bottom: 10px;
          text-align: center;

          small {
            font-size: 14px;
            font-weight: 300;
          }
        }
      }
    }

    &.alert {
      .modal-footer {
        button {
          margin-bottom: 0;
        }
      }
    }
  }
}
