@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.geolocation-modal {
  padding: 50px 25px 30px 25px;
  text-align: center;
  position: relative;

  @include desktop {
    padding-left: 55px;
    padding-right: 55px;
  }

  .modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    svg {
      stroke: white;
      height: 20px;
      width: 20px;
      vertical-align: middle;
    }
  }

  .modal-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;

    @include desktop {
      font-size: 28px;
    }
  }

  .modal-body {
    p.main {
      margin-bottom: 30px
    }
    button {
      font-size: 18px;
      margin-bottom: 25px;
      width: 240px;
    }
    p.muted {
      color: $gray4;
      font-size: 14px;
    }
  }
}
