@import "src/ui-kit/_variables.scss";
// @import "src/ui-kit/_mixins.scss";

.government-id-form,
.work-disclosures-form,
.policy-updates-form {

  .government-id-fields,
  .work-disclosures-fields,
  .policy-updates-fields {
    h3 {
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
    }

    a {
      text-decoration: underline;
    }

    .associate-signature-header {
      align-items: center;
      display: flex;
      font-size: 14px;
      justify-content: space-between;

      .clear-signature {
        align-items: center;
        color: red;
        cursor: pointer;
        display: flex;
        font-weight: bold;
        line-height: 0;

        svg {
          stroke: red;
          stroke-width: 3;
        }
      }
    }

    .associate-signature-wrapper {
      border: 2px solid $gray6;
      border-radius: 3px;
      height: 150px;
      padding: 5px;
      display: flex;

      img {
        margin: auto;
      }
    }

    .associate-signature-label {
      color: $gray5;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      margin-top: 10px;
      text-align: center;
    } 
  }

  .container-fluid {
    padding: 0;
  }
}

.government-id-footer,
.work-disclosures-footer,
.policy-updates-footer {
  display: flex;
  padding: 0 4rem;
  @media (max-width: $mobile-wide-footer-width) {
    flex-direction: column;
    padding: 0 5.3rem 0 0;
  }
  @media (max-width: $desktop-width) {
    padding: 0 5.3rem 0 0;
  }
  @media (max-width: $desktop-width) and (min-width: $mobile-wide-footer-width) {
    padding: 0 5.3rem;
  }
  &>button {
    flex: 1 0 45%;
    text-align: center;

    &:first-child {
      margin-right: 0.6rem;
    }
    &:last-child {
      margin-left: 0.6rem;
      @media (max-width: $mobile-wide-footer-width) {
        margin-top: 0.6rem;
        margin-left: 0;
      }
    }
  }
}
