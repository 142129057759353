@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.work-interests {
  .interests-header {
    text-align: center;
    margin: 0 0 28px;
  }
  h2 {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 20px;
  }

  ul.interests-list {
    margin-bottom: 40px;

    li {
      border-top: 2px solid #eee;
      padding: 5px 0;
      position: relative;
      @include desktop {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
      }
      &:first-child {
        border-top: none;
      }
      & > .input-line {
        flex: 1;
        margin: 0;
        @include mobile {
          margin-bottom: 10px;
        }
        .input-box input[type="checkbox"] + label,
        .input-box input[type="radio"] + label {
          font-size: 18px;
          line-height: 30px;
          font-weight: bold;
          padding: 0 0 0 32px;
          @include desktop {
            padding: 0 0 0 38px;
          }
        }
        .input-box input[type="checkbox"] + label {
          &:before {
            top: 5px;
            @include desktop {
              top: 4px
            }
          }
          &:after {
            top: 10px;
            @include desktop {
              top: 9px
            }
          }
        }
      }
      .experience-select {
        flex-basis: 30%;
        @include mobile {
          margin-bottom: 5px;
        }
      }
    }
  }

  .expand-section {
    padding: 0 0 0 41px;
    @media (max-width: 767px) {
      margin-top: 0;
      padding-left: 32px;
    }
    &.expanded {
      margin: 12px 0 0 0;
      padding: 0 0 0 41px;
      @media (max-width: 767px) {
        margin: 13px 0 0 0;
        padding-bottom: 10px;
        padding-left: 32px;
      }
    }
    h6 {
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
    }
    textarea {
      height: 120px
    }
  }
  .job-filter {
    margin: 31px 0 0;
    @media (max-width: 767px) {
      margin: 17px 0 0;
      text-align: left;
    }
    p {
      font-size: 20px;
      line-height: 30px;
      display: inline-block;
      vertical-align: middle;
      margin: 0 6px;
      @media (max-width: 767px) {
        font-size: 18px;
        margin: 0;
      }
    }
    .select-container {
      width: 75px;
      display: inline-block;
      margin: 0 3px;
      vertical-align: middle;
      @media (max-width: 767px) {
        width: 100%;
        margin: 5px 0;
        position: relative;
      }
    }
    .Select {
      .Select-control {
        height: 50px;
        .Select-value {
          padding-top: 7px;
          padding-bottom: 7px
        }
      }
      .Select-arrow-zone {
        padding-right: 8px;
      }
    }

    input[type="text"] {
      width: 140px;
      padding: 9px 15px 10px;
      display: inline-block;
      vertical-align: middle;
      @media (max-width: 767px) {
        width: 100%;
        padding: 11px 14px 10px;
      }
    }
    .input-box {
      display: inline-block;
      @media (max-width: 767px) {
        display: block;
        margin: 5px 0;
      }
    }
  }
  .info-text {
    display: none;
    @media (max-width: 767px) {
      display: block;
      margin: 5px 0 7px;
    }
    &.textarea-counter {
      display: block
    }
  }
  .continue-button {
    max-width: 400px;
    margin: 30px auto 0;
    @media (max-width: 767px) {
      margin: 20px auto 0;
    }
  }
}
