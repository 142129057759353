@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.tooltip-wrapper {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 4px 0 0 4px;
  
  @include mobile {
    position: static;
    margin: 1px 0 0 6px;
  }

  .tooltip-icon-caption {
    white-space: nowrap;
    display: flex;

    .caption {
      color: $dark-blue;
      font-size: 11px;
      font-weight: bold;
      float: right;
      margin-left: 5px;
      border-bottom: .0625rem dashed currentColor;

      @include mobile {
        font-size: 10px;
      }
    }
  
    svg {
      width: 16px;
      height: 16px;
      cursor: help;
      fill: #7c8284;
  
      @include mobile {
        width: 14px;
        height: 14px;
      }
    }

    &:hover {
      svg {
        fill: $dark-blue;
      }

      & + .tooltip{
        display: block;
      }
    }
  }
  
  .tooltip {
    width: 320px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: #fff;
    background-color: $gray2;
    padding: 10px 10px 10px 13px;
    @include border-radius(4px);
    white-space: break-spaces;
    display: none;
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    margin: 0 0 7px -152px;
    z-index: 1000;

    @include mobile-and-tablet {
      margin: auto;
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
      top: auto;
      bottom: 18px;
      left: 3%;
      right: 3%;
      margin: auto;
    }

    @include mobile {
      width: 94%;
      max-width: 440px;
      top: auto;
      bottom: 18px;
      left: 3%;
      right: 3%;
      margin: auto;
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: $gray2 transparent transparent transparent;
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1000;

      @include mobile-and-tablet {
        right: 305px;
      }
      
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
        right: 305px;
      }

      @include mobile {
        display: none;
      }
    }
  }
}
