@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

$dayWidth: 14.2857%;

.vw-cal-day {
  display: flex;
  flex: 0 0 14.2857%;
  align-items: center;
  justify-content: center;
  @include box-shadow-border(.0625rem, $border-gray);
  color: $gray4;
  cursor: default;
  font-weight: 500;
  line-height: 26px;
  position: relative;
  height: 61px;
  padding: 5px;



  &-number {
    @include mobile {
      font-size: 12px;
    }
  }

  &.past,
  &.disabled {
    cursor: default
  }

  &.is-event {
    background-color: $green4;
    @include box-shadow-border(.0625rem, $green2);
    color: $green1;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    z-index: 1;

    &.penalty {
      background: #fbedd0;
      @include box-shadow-border(.0625rem, #d5cbb4);
      color: #757F88;
    }


  }
  &.unavailable {
    background-color: $green4;
    color: $green1;
    @include box-shadow-border(.0625rem, $green2);
    cursor: default;
  }

  &.conflict {
    background-color: rgba(237, 77, 93, .1);
    @include box-shadow-border(.0625rem, #ed4d5d);
    position: relative;
    z-index: 2;
    color: #ed4d5d;
    font-weight: bold;
  }

  &.error {
    .checkbox {
      border-color: #ff6675;
    }
  }

  &.startOfMonth {
    // border-left-width: 1px;

    &.monday {
      margin-left: $dayWidth;
    }
    &.tuesday {
      margin-left: $dayWidth * 2;
    }
    &.wednesday {
      margin-left: $dayWidth * 3;
    }
    &.thursday {
      margin-left: $dayWidth * 4;
    }
    &.friday {
      margin-left: $dayWidth * 5;
    }
    &.saturday {
      margin-left: $dayWidth * 6;
    }
  }

  &.sunday {
    border-left-width: 1px;
  }

  .checkbox {
    background-color: white;
    border-radius: 3px;
    border: 2px solid #b6bebf;
    height: 16px;
    width: 16px;
    position: absolute;
    top: 3px;
    right: 3px;

    @include mobile {
      position: relative;
      right: auto;
    }

    &.checked {
      background-color: $green2;
      border-color: $green2;

      &.unavailable {
        background-color: gray;
        border-color: gray;
      }

      &.cancelled {
        background-color: #ff6b6b;
        border-color: #ff6b6b;

      }

      svg {
        fill: white;
        stroke: white;
        position: relative;
        bottom: 9px;
        left: 1px;
      }
    }
  }

  &.disabled {
    .checkbox {
      border-color: #b1bac3 !important;
      background-color: #fff !important;
      svg {
        fill: #b1bac3 !important;
        stroke: #b1bac3!important;
      }
    }
  }

  @include mobile {
    flex-direction: column;
    height: 45px;
  }
}
