@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

svg.bed {
  fill: $ocean-blue;
}

svg.car {
  fill: $gray4;
}

svg.hot {
  fill: #f0924d;
}

svg.self-assign {
  fill: #4b6acd;
}

svg.night {
  fill: $purple2;
}

svg.users {
  fill: #aaa;
}
