@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.terms-content {
  header {
    text-align: center;

    .marked-header {
      margin-top: 0px !important;
    }
  }

  a {
    text-decoration: underline;
    font-weight: bold;

    &:visited {
      color: $gray3;
    }

    &.mailto {
      text-decoration: none;
      color: $gray3;
    }
  }

  footer {
    margin-top: 80px;
  }

  max-width: 1170px;
  margin: 0 auto;
  margin-top: 60px;
  overflow-y: auto;
  padding: 20px !important;

  @include desktop {
    padding: 45px 0
  }

  h2, h3, p {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .marked-header {
    color: $blue1;
  }
}
