@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.job-detail-map-location {
  .location-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .header-left {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      svg {
        fill: $gray6;
        margin: 3px 11px 0 0
      }
    }

    .btn {
      line-height: normal;
    }

    @include mobile {
      .location-address {
        font-weight: normal;
      }
    }
  }
  .location-map {
    margin-top: 19px;
    border-radius: 3px;
  }
}
