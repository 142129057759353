@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.gamification {
  background-color: white;
  border: 1px solid $border-gray;
  border-radius: 8px;
  margin: 5px;
  text-align: center;

  &-header {
    display: flex;
    align-items: center;
    padding: 13px;
    cursor: pointer;

    @include tablet-and-wide-tablet {
      flex-direction: column;
    }

    @include tablet {
      border-bottom: 1px solid $gray7;
    }

    @include desktop {
      border-bottom: 1px solid $border-gray;
      padding: 25px 55px;
    }

    @include desktop-until-desktop-medium {
      padding: 25px;
    }
  }

  &-badge {
    float: left;
    margin-right: 14px;
    flex: 0 1 60px;

    img {
      width: 100%;
    }

    @include tablet {
      margin-right: 22px;
      flex: 0 1 100px;
    }


    @include desktop {
      margin-right: 22px;
      flex: 0 0 50%;
    }

    @include tablet-and-wide-tablet {
      margin-right: 0;
      margin-bottom: 10px;
    }

    @include desktop-until-desktop-medium {
      margin-right: 11px;
    }
  }

  &-label {
    flex: 1;
    color: $black-light;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    text-align: left;

    @include tablet {
      width: 100%;
    }

    .gamification-label-wrapper {
      @include mobile {
        display: flex;
        align-items: center;
      }

      .gamificaton-status-wrapper {
        width: 100%;
      }
    }

    .gamificaton-status {
      color: #757f88;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      margin-left: 5px;

      @include tablet {
        margin-left: 0;
      }

      @include desktop {
        margin-left: 0
      }
    }

    .points-summary-mobile {
      float: right;
      display: block;
      svg {
        stroke: #c9cfd0;
      }

      @include desktop {
        display: none
      }
    }

    @include tablet-and-wide-tablet {
      text-align: center;
    }

    @include desktop {
      font-size: 24px;
      line-height: 30px;

      span {
        display: block;
        font-size: 18px;
        line-height: 22px;
        margin-left: 0
      }
    }

    @include desktop-until-desktop-medium {
      font-size: 20px;
    }

    .gamification-bar {
      @include tablet {
        display: none;
      }

      @include desktop {
        display: none
      }
    }

    p.level-up {
      float: left;
      color: $gray5;
      font-size: 14px;
      font-weight: 500;

      @include tablet {
        display: none;
      }

      @include desktop {
        display: none
      }
    }

    p.points-left {
      color: $gray2;
      float: right;
      font-size: 14px;

      @include tablet {
        display: none;
      }

      @include desktop {
        display: none
      }
    }
  }

  &-progress {
    border-bottom: 1px solid $border-gray;
    padding: 25px 20px;

    p {
      color: $black-light;
      font-size: 18px;
      line-height: 22px;
    }

    @include mobile {
      display: none
    }

    @include tablet-and-wide-tablet {
      padding: 20px 10px;
    }
  }

  &-stats {
    display: flex;
    justify-content: center;
    color: black;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    margin-bottom: 20px;

    .gamification-numbers {
      @include tablet-and-wide-tablet {
        font-size: 30px;
      }
    }

    h5 {
      font-size: 12px;
      color: $light-gray;
      letter-spacing: 1px;
      line-height: 15px;
      text-transform: uppercase;
    }

    @include tablet {
      margin-bottom: 0;
    }
  }

  &-points, &-next-level {
    flex: 1;
    padding: 0 20px;
    text-align: center;

    @include tablet-and-wide-tablet {
      padding: 0 10px;
    }
  }

  &-next-level {
    border-left: 1px solid $border-gray;
  }

  &-bar {
    margin: 5px 0;
    
    @include tablet {
      margin-bottom: 10px;
    }

    &-outer {
      background-color: #cddcef;
      border-radius: 100px;
      height: 5px;
      width: 100%;

      /* this is the progress bar */
      div {
        transition: width 1s;
        height: 5px;

        @include desktop {
          height: 12px;
        }
      }
    }

    &-inner {
      background-color: $blue1;
      border-radius: 100px;
    }

    @include desktop {
      margin: 12px 20px;

      &-outer {
        height: 12px;
      }
    }
  }

  &-training {
    border-bottom: 1px solid $border-gray;
    padding: 25px 20px;

    @include mobile {
      display: none
    }

    h5 {
      font-size: 12px;
      color: $light-gray;
      letter-spacing: 1px;
      line-height: 15px;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    &-badges {
      .badge {
        background-color: #f3f8ff;
        border: 1px solid #cddcef;
        border-radius: 5px;
        color: #3877C2;
        font-size: 13px;
        font-weight: 500;
        padding: 2px 9px;
        margin: 6px 2px 0;
        display: inline-block;
      }

      p {
        color: #333333;
        font-size: 18px;
        line-height: 22px;
        margin-top: 6px;
      }
    }
  }

  &-summary {
    @include mobile {
      display: none
    }
    padding: 15px 0;
    text-align: center;
  }

  button {
    margin: 0 auto;
  }
}
