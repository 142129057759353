.dd-menu-items {
  border: 1px solid #dee2e3;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,0.05);
  position: absolute;
  z-index: 100;

  &:before, &:after {
    bottom: 100%;
  	left: 50%;
  	border: solid transparent;
  	content: " ";
  	height: 0;
  	width: 0;
  	position: absolute;
  	pointer-events: none;
  }

  &:before {
    border-color: rgba(222, 226, 227, 0);
  	border-bottom-color: #dee2e3;
  	border-width: 9px;
  	margin-left: -9px;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
  	border-bottom-color: #fff;
  	border-width: 8px;
  	margin-left: -8px;
  }
}
