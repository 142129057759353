@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.Select {
  > .Select__menu {
    border-width: 2px;
    border-top-color: transparent;
    z-index: 10;
    margin: -2px 0 0 0;
    box-shadow: none;

    .Select__menu-list {
      padding: 10px;
      background: #FFF;

      .Select__option {
        border-radius: 4px;
        color: inherit;
        font-size: 17px;
        padding: 2px 10px;

        &--is-selected {
          color: inherit;
          background: #FFF;
          font-weight: bold;
        }
        &--is-focused {
          background-color: #dfebfa;
          color: #5b85be;
        }
        
        @include mobile {
          font-size: 18px
        }
      }
    }
  }

  > .Select__control {
    background-color: #fff;
    border: 2px solid #b6bdbf;
    box-shadow: none;
    font-weight: 500;
    font-size: 1.0625rem;
    line-height: 1.24;
    font-family: 'Macha', 'Arial', 'Helvetica', 'sans-serif';
    height: 45px;

    @include mobile {
      font-size: 18px;
      height: 47px;
    }

    .Select__value-container {
      &, .Select__placeholder {
        color: #4D4F51;
        white-space: nowrap;
        font-family: 'Macha', 'Arial', 'Helvetica', 'sans-serif';
        padding: 10px 12px;
        font-size: 1.0625rem;
        line-height: 1.24;
      }

      .Select__placeholder {
        color: #b6bdbf;
        left: 0;

        @include mobile {
          padding-top: 6px;
          padding-bottom: 6px;
        }
      }

      > div {
        &:last-child {
          position: absolute;

          .Select__input {
            input {
              box-shadow: none;
            }
          }
        }
      }

      [class$='-dummyInput'] {
        position: absolute;
      }

      &--is-multi {
        margin: 0;
        padding: .25rem;

        @include mobile {
          line-height: 24px;
        }

        > div {
          &:last-child {
            position: static;
            margin-left: .5rem;
          }
        }

        .Select__multi-value {
          background-color: rgba(0, 126, 255, .08);
          border: 1px solid rgba(0, 126, 255, .24);

          &__label {

          }

          &__remove {
            cursor: pointer;
            border-left: 1px solid rgba(0, 126, 255, .24);

            &:hover {
              background-color: rgba(0, 113, 230, .08);
              color: #0071e6;
            }
          }
        }
      }
    }
  
    .Select__indicators {
      .Select__indicator {
        &.Select__dropdown-indicator {
          padding-left: .125rem;
        }
      }
    }

    &--menu-is-open {
      &,
      &:hover,
      &:focus {
        border-color: #5b85be;
        border-bottom-color: transparent;
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
      }

      & + .Select__menu {
        border: 2px solid #5b85be;
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }

      &.Select__position--top {
        &,
        &:hover,
        &:focus {
          border-color: #5b85be;
          border-top-color: transparent;
          z-index: 11;
        }

        & + .Select__menu {
          border: 2px solid #5b85be;
          border-radius: .25rem;
          border-bottom: 0;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          margin-bottom: -.125rem;
        }
      }
    }

    &--is-focused {
      &:not(.Select__control--menu-is-open) {
        border-color: #5b85be;
        box-shadow: none;
      }
    }
  }

  &--is-multi {
    > .Select__control {
      min-height: 45px;
      height: auto;
    }
  }

  &--is-disabled {
    opacity: .6;
  }

  .input-box & {
    &.Select--has-value {
      &, &:hover {
        .Select__control {
          border-color: #8b9699;

          &--menu-is-open {
            &,
            &:hover,
            &:focus {
              border-color: #5b85be;
              border-bottom-color: transparent;
              box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
            }

            &.Select__position--top {
              &,
              &:hover,
              &:focus {
                border-color: #5b85be;
                z-index: 11;
                border-top-color: transparent;
              }
            }
          }

          &--is-focused {
            &:not(.Select__control--menu-is-open) {
              border-color: #5b85be;
              box-shadow: none;
            }
          }
        }
      }

      & + label{
        opacity: 1;
        top: -8px;
      }
    }

    &.has-errors {
      .Select__control {
        border-color: $red;
      }

      .Select__menu {
        border-color: $red;
      }

      & + label {
        color: $red;
        opacity: 1;
        top: -8px;
      }
    }
  }

  .input-box &,
  & {
    &--is-disabled {
      opacity: 1;

      .Select__control {
        &.Select__control--is-disabled {
          border-color: #8B9699;
          background: #F7F8F9;

          .Select__value-container {
            &, .Select__placeholder {
              color: $input-disabled-color;

              .Select__single-value {
                color: $input-disabled-color;
              }
            }
          }
        }
      }
    }
  }
}

.signup-basic,
.signup-personal {
  /* overrides for the large input fields on signup pages */
  .Select {
    > .Select__control {
      font-size: 22px;
      height: 45px;
      line-height: 1.23;

      .Select__value-container {
        &, .Select__placeholder {
          font-size: 22px;
          padding: 10px 15px;
        }
      }
    }
    
    > .Select__menu {
      &-list {
        padding: 10px;
        .Select__option {
          font-size: 22px;
        }
      }
    }
  }
}
