@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.content {
  .pagination-bottom {
    @include mobile {
      padding: 0 15px;
    }
    .-pagination {
      border-top: none;
      box-shadow: none;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex: 1;
      padding: 3px;
      z-index: 1;

      .-previous {
        flex: 1 1;
        text-align: left;
        @include desktop {
          text-align: right;
        }
      }

      .-center {
        text-align: center;
        margin-bottom: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
      }

      .-next {
        flex: 1 1;
        text-align: right;
        @include desktop {
          text-align: left;
        }
      }

      .-pageInfo {
        margin: 0 5px;
        white-space: normal;
        @include mobile {
          display: none
        }
      }

      .page-link {
        border: 1px solid #dee2e3;
        border-radius: 100px;
        color: #757f88;
        cursor: pointer;
        display: inline-block;
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        margin: 0 5px 10px;
        padding: 6px 15px;

        &.current {
          background-color: #e1eaf6;
          color: #5984c0;
          font-weight: bold;
        }
      }
    }
  }
}

.pagination-next,
.pagination-prev {
  background-color: white;
  border: 1px solid $blue1;
  border-radius: 50%;
  display: inline-block;
  height: 30px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;

  svg {
    stroke: $blue1;
  }

  &.disabled {
    background-color: $gray9;
    border-color: $gray8;

    cursor: disabled;

    svg {
      stroke: #c1c7c8;
    }
  }
}

.pagination-next {
   padding: 4px 5px 4px 7px;
}

.pagination-prev {
   padding: 4px 7px 4px 5px;
}
