@import "src/ui-kit/_variables.scss";

.application-modal-footer {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 75px;
  background-color: white;

  @media (max-width: $tablet-modal-footer-width) {
    padding-right: 80px;
  }

  @media (min-width: $desktop-width) {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @media (max-width: $mobile-modal-footer-width) {
    flex-direction: column;
    padding: 0 80px 0 0;
  }

  &>button {
    flex: 1 0 40%;
    text-align: center;

    &:first-child { 
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
      @media (max-width: $tablet-modal-footer-width) {
        margin-left: 0;
      }

      @media (max-width: $mobile-modal-footer-width) {
        margin-top: 10px;
      }
    }
  }
}
