@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.points-totals {
  display: flex;
  border: 1px solid $border-gray;
  border-radius: 4px;	background-color: #FFFFFF;
  margin-bottom: 15px;


  &>div {
    flex: 1;
    border-right: 1px solid $border-gray;
    padding: 25px 0;
    text-align: center;

    h5 {
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 15px;
      text-transform: uppercase;
    }

    span {
      display: inline-block;
      font-size: 28px;
      font-weight: 400;

      @include desktop {
        font-size: 53px;
      }
    }

    &:last-child {
      border-right: none
    }
  }

  .points-earned { color: $green2 }
  .points-lost { color: $red }
  .net-points { color: $black-light }
}

.points-log {
  max-height: 400px;
}

.points-log-row {
  border-bottom: 1px solid $border-gray;
  display: flex;
  padding: 20px 0;

  @include desktop {
    padding-left: 5px;
    padding-right: 5px
  }

  .no-points {
    text-align: center;
  }


  .vertical-date {
    flex: 0 0 15%;
    text-align: center;

    @include desktop {
      flex: 0 0 10%;
    }

    .month {
      display: block;
      color: #757f88;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
    }
    .day {
      color: $black-light;
      font-size: 23px;
      font-weight: bold;
      line-height: 29px;
    }
  }

  .notes {
    color: $black-light;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    flex: 1;

    @include desktop {
      font-size: 20px;
      line-height: 25px;
    }
    .client, .job {
      color: #4e555c;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
    }

    .job {
      color: $gray5;
    }
  }

  .points {
    flex: 0 0 15%;
    text-align: center;

    @include desktop {
      flex: 0 0 10%;
    }
    .title {
      display: block;
      color: #757f88;
      font-size: 11px;
      font-weight: 500;
      line-height: 13px;
    }
  }
  &:last-child {
    border-bottom: none
  }
}

.points-pagination {
  .previous {
    float: left
  }
  .next {
    float: right
  }
}
