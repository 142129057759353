@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.disclosures-item {
  padding: 20px 0;
  border-bottom: 1px solid $border-gray;

  h5 {
    font-size: 18px;
  }

  @include mobile {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include tablet {
    padding: 20px;
    
    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom: none;
    }
  }
}
