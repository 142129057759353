@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

button, .btn {
  font: bold 20px/25px 'Macha', 'Arial', 'Helvetica', 'sans-serif';
  color: #fff;
  padding: 12px 0;
  display: inline-block;
  text-align: center;
  @include border-radius(5px);
  @include transition(background-color 0.1s);

  .success { display: none }

  &-block {
    width: 100% !important
  }

  &-edit {
    background-color: $light-gray;

    .active {
      display: block
    }

    &.success {
      background-color: $green2;
      .success {
        display: block;
      }
      .active { display: none }
    }
  }

  &-save {

    width: 130px;

    &.saved {
      .success {
        display: block;
        img {
          display: block;
          margin: 0 auto;
        }
      }
      .active { display: none }
    }
  }

  &-login {
    height: 50px;
    .active {
      display: flex;
      justify-content: center;
      vertical-align: middle;

      img {
        margin-right: 8px;
      }
    }

    svg{
      stroke: #fff;
      position: relative;
      left: 5px;
      vertical-align: middle;
    }
  }

  &-apply {
    padding: 15px 10px;

    @include desktop {
      padding-left: 38px;
      padding-right: 38px
    }
  }

  &-danger {
    background-color: $red;
    padding: 15px 10px;

    &:hover {
      background-color: #d44757;
    }

    &:active {
      background-color: #b73d4a;
    }

    @include desktop {
      padding-left: 38px;
      padding-right: 38px
    }
  }

  &-cancel {
    background-color: #b1bac3;
    padding: 15px 10px;

    @include desktop {
      padding-left: 38px;
      padding-right: 38px
    }

    &:hover {
      background-color: #80868b;
    }

    &:active {
      background-color: #6f7479;
    }

    &:disabled {
      &, &:hover, &:active {
        background-color: #b1bac3;
        cursor: not-allowed;
      }
    }
  }

  &-green{
    background-color: $green2;

    &:hover{
      background-color: #57a654;
    }

    &:disabled {
      background-color: #dee2e3;
      color: #99a1a8;
      cursor: not-allowed;

      &.saved { background-color: $green2 }

      &:hover {
        background-color: #ced2d3;
      }
    }
  }

  &-view {
    background-color: #f3f8ff;
    border: 1px solid #cddcef;
    border-radius: 3px;
    color: $blue1;
    font-size: 13px;
    font-weight: 500;
    padding: 8px 30px;
  }

  &-small {
    font-size: 13px;
    padding: 5px 18px;
  }
}
