// Primary Colors
$dark-blue:   #5B85BE;
$blue1:       #3877C2;
// $blue2:    #5984C0;
// $blue3:    #97B5DB;
// $blue4:    #E1EAF6;
$blue5:       #F3F8FF;

$green1:      #57A655;
$green2:      #63BE60;
$green3:   #8ACC87;
$green4:   #DCF2DB;

$gray1:       #333333;
$gray2:       #4A4A4A;
$gray3:       #4D4F51;
$gray4:       #757F88;
$gray5:       #99A1A8;
$gray6:       #B6BDBF;
$gray7:       #DDDDDD;
$gray8:       #DFE2E3;
$gray9:       #F7F8F9;
$gray10:      #FDFDFD;
$white:       #FFFFFF;

// Secondary Colors
$ocean-blue:  #0DBACB;
$deep-blue:   #394C94;
$purple-blue: #4967D0;
$purple:      #6C4FAC;
$purple2:     #6C52A9;
$red:         #EB4F60;
$light-red:   lighten($red, 5%);
$salmon:      #ED4D5D;
$mustard:     #F29344;
$dark-orange: #F86548;

$black-light: $gray1;
$medium-gray: $gray2;
$light-gray:  $gray5;
$border-gray: $gray7;
$dark-gray:   #4D4F51;

$input-disabled-color: #a2a2a2;

// Breakpoints
$mobile-modal-footer-width: 462px;
$mobile-wide-footer-width: 546px;
$tablet-modal-footer-width: 680px;
$tablet-width:              768px;
$tablet-wide-width:         990px;
$desktop-width:             1023px;
$desktop-medium-width:      1200px;
$desktop-wide-width:        1350px;

// Shared
$navbar-height:      80px;
