@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.points-modal {
  .modal-content {
    height: calc(100% - 40px);
    width: 768px;
  }

  &-menu {
    display: flex;
    font-size: 12px;
    padding-right: .5rem;

    @include desktop {
      font-size: 15px;
    }

    li {
      cursor: pointer;
      padding: .25rem .5rem;
      line-height: 1.25;
      white-space: nowrap;

      @include desktop {
        padding: .375rem .75rem;
      }

      @media (max-width: 380px) {
        flex-grow: 1;
        flex-basis: 0;
        white-space: normal;
      }

      &.active {
        background-color: #31639e;
        border-radius: .5rem;
        text-decoration: none;

        @include desktop {
          border-radius: 100px;
        }
      }

      & + li {
        @include desktop {
          margin-left: .5rem;
        }
      }
    }
  }

  .modal-header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    padding: 20px;
    text-align: center;

    &.blue {
      background-color: $blue1;
      color: white;
      font-size: 18px;
      line-height: 30px;
      padding: 26px 40px 26px 24px;
      text-align: left;

      @include mobile {
        padding: 20px 26px 20px 20px
      }
    }
  }

  .modal-close {
    float: right;

    svg {
      display: block;
      stroke: white;
      height: 20px;
      width: 20px;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .compare-levels {
    padding: 35px 0px;
    text-align: center;

    &-header {
      margin-bottom: 40px;
      padding: 0 20px;

      @include mobile {
        br {
          display: none
        }
      }
    }

    h2 {
      font-size: 28px;
      margin-bottom: 0;
      @include mobile {
        font-size: 24px;
        margin-bottom: 10px;
      }
    }

    img {
      margin-bottom: 10px;
      width: 100px;
    }
    h3 { font-size: 18px }
    h4 {
      font-size: 24px;
      font-weight: normal;
      margin-bottom: 20px;
      &:after {
        content: '+'
      }
    }

    .levels-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      @include mobile {
        grid-template-columns: 100%
      }
    }

    .level-column {
      border-right: 1px solid $gray8;
      padding: 0 20px;

      @include mobile {
        padding: 0 40px;
        margin-bottom: 20px;
      }

      &:last-child {
        border-right: none
      }
    }

    ul {
      margin-left: 15px;
      li {
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        list-style-image: url('/assets/icon-tick-blue.svg');
        list-style-position: outside;
        margin-bottom: 10px;
        text-align: left;

        @include mobile {
          font-size: 16px
        }
      }
    }
  }

  .points-summary-header {
    display: flex;
    border-bottom: 1px solid $border-gray;
    background-color: #f7f8f9;
    color: $light-gray;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 15px 40px 15px 40px;
    text-transform: uppercase;

    @include mobile {
      padding-left: 20px;
      padding-right: 20px;
    }

    &>div {
      flex: 1
    }
    .category {
      flex-basis: 65%;
      text-align: left;
    }
    .points-earned {
      flex-basis: 35%;
      text-align: right;
    }
  }

  .points-summary-body {

    &.with-footer {
      height: 435px;
    }
    .points-summary-row {
      border-bottom: 1px solid $border-gray;
      display: flex;
      align-items: center;
      padding: 15px 40px;

      @include mobile {
        padding-left: 20px;
        padding-right: 20px;
      }

      &>div {
        flex: 1
      }
      .category {
        color: $black-light;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        flex-basis: 75%;
        .value {
          border: 1px solid #d3d6d7;
          border-radius: 100px;
          color: #757f88;
          display: inline-block;
          font-size: 11px;
          font-weight: bold;
          height: 17px;
          line-height: 12px;
          padding: 2px 5px;
          margin-left: 6px;
          vertical-align: middle;
          .positive {
            &:before {
              content: "+";
            }
          }
        }
      }
      .points-earned {
        font-weight: 500;
        flex-basis: 25%;
        font-size: 23px;
        line-height: 28px;
        text-align: right;
        .positive {
          color: $green2;
          &:before {
            content: "+";
            font-size: 11px;
            font-weight: bold;
            vertical-align: super;
          }
        }
        .negative {
          color: $red
        }
        .count {
          color: #757f88;
          font-size: 11px;
          font-weight: bold;
          line-height: 13px;
          margin-left: 4px;
          vertical-align: middle
        }
      }
      &:last-child {
        border-bottom: none
      }
    }
  }

  .points-summary {
    display: flex;
    background-color: #f7f8f9;
    border-top: 1px solid $border-gray;
    color: $black-light;
    font-size: 12px;
    font-weight: bold;
    padding: 1.6rem 2.6rem;
    text-transform: uppercase;

    @media (max-width: $tablet-wide-width) {
      padding: 1.5rem 8rem;
    }

    @media (max-width: $mobile-modal-footer-width) {
      padding: 1.5rem 6rem;
    }

    &>div {
      flex: 1
    }
    .category {
      flex-basis: 65%;
      letter-spacing: 1px;
      line-height: 45px;
    }
    .points-earned {
      color: black;
      flex-basis: 35%;
      font-size: 36px;
      font-weight: 400;
      line-height: 45px;
      text-align: right;
    }
  }
}
