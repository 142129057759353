@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.app-loading {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100vh;

  &-indicator {
    margin: auto;
    text-align: center;
  }

  svg {
    fill: $gray5;
    margin-bottom: 5px;
  }

  p {
    color: $gray5;
  }
}
