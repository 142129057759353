@import "src/ui-kit/_variables.scss";

.mobile-navbar {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 60px;
  background-color: $blue1;

  @media (min-width: $desktop-width + 1) {
    display: none;
  }

  .container {
    padding: 0 15px;
  }

  &-menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    &>li {
      flex: 1;
      padding: 10px 0;
      position: relative;
      &>a {
        color: #cddcef;
        display: block;
        font-size: 11px;
        font-weight: bold;
        line-height: 13px;
        text-align: center;

        svg { fill: #cddcef }
        span {
          display: block;
          margin-top: 3px
        }

        &.tasks-link {
          svg {
            fill: #cddcef;
            stroke: #cddcef;
            height: 19px;
            width: 17px;
          }
        }
      }
      &.active {
        a {
          color: white;
          svg {
            fill: white
          }
        }
        &:before, &:after {
          bottom: -2px;
        	left: 50%;
        	border: solid transparent;
        	content: " ";
        	height: 0;
        	width: 0;
        	position: absolute;
        	pointer-events: none;
        }

        &:before {
          border-color: rgba(222, 226, 227, 0);
        	border-bottom-color: #dee2e3;
        	border-width: 6px;
        	margin-left: -6px;
        }

        &:after {
          border-color: rgba(255, 255, 255, 0);
        	border-bottom-color: #fff;
        	border-width: 5px;
        	margin-left: -5px;
        }
      }
    }

    .menu-toggle {
      div {
        border-radius: 4px;
        width: 28px;
        height: 3px;
        background-color: #cddcef;
        margin: 5px auto;
        &:first-child {
          margin-top: 0;
        }
      }
    }

    .profile-picture {
      text-align: center;
      padding: 5px 0;
      width: 24px;
      font-size: 12px;
      line-height: 14px;
      margin: 0 auto;
    }
  }
}

.bm-overlay,
.bm-menu-wrap {
  @media (min-width: $desktop-width + 1) {
    display: none;
  }

  &.bm-menu-wrap {
    max-width: 100%;
  }
}