@import "src/ui-kit/_variables.scss";

.point-value {
  font-weight: 500;
  flex-basis: 25%;
  font-size: 23px;
  line-height: 28px;
  text-align: center;
  .positive {
    color: $green2;
    &:before {
      content: "+";
      font-size: 11px;
      font-weight: bold;
      margin-right: 2px;
      vertical-align: super;
    }
  }
  .negative {
    color: $red;
    &:before {
      content: "-";
      font-size: 11px;
      font-weight: bold;
      margin-right: 2px;
      vertical-align: super;
    }
  }
  .count {
    color: #757f88;
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    margin-left: 4px;
    vertical-align: middle
  }
}
