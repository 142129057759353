@import "src/ui-kit/_variables.scss";

.loading {
  padding: 20px 0;
  text-align: center;

  p {
    color: $blue1;
    font-weight: bold;
  }
}
