@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.timesheet-form {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .error-message-wrapper {
    margin: 10px 0;
  }

  .timesheet-header {
    padding: 20px 20px;

    @include desktop {
      padding: 20px 35px;
    }

    @media screen and (max-width: 800px) and (orientation: landscape) {
      padding: 20px 20px 5px;
    }

    &-title {
      h2 {
        color: $blue1;
        font-size: 20px;
        font-weight: bold;
        line-height: 30px;
        margin-bottom: 0;
        text-align: left;

        @include desktop {
          font-size: 28px;
        }
      }
    }

    .tooltip-wrapper {
      margin-top: .33rem;

      .tooltip {
        bottom: auto;
        margin-bottom: 0;
        margin-top: 7px;

        &:after {
          top: -5px;
          bottom: auto;
          border-width: 0 5px 5px 5px;
          border-color: transparent transparent #4A4A4A transparent;
        }
      }
    }

    &.minimal {
      padding-bottom: 5px;

      @include desktop {
        padding: 35px 35px 10px;
      }
    }
  }

  .timesheet-footer {
    border-top: 1px solid $gray8;
    box-shadow: 0 -1px 2px 0 rgba(0,0,0,0.08);
    width: 100%;
    z-index: 1;
    bottom: 0;
    padding: 1.73rem 2.3rem;
    padding-right: 5.6rem;

    @include desktop {
      background-color: white;
      border: 0;
      position: sticky;
    }

    &.fixed {
      position: initial;
      background-color: white;
      width: 100vw;
      padding-left: 1.25rem;
      padding-right: 6.25rem; 

      @include desktop {
        border: 0;
        margin: 0 0 -1.3rem -2.3rem;
        width: 620px;
      }
    }
  }

  .timesheet-fields,
  .timesheet-review,
  .timesheet-signature {
    flex: 1;
    padding: 0 20px 20px;

    @include desktop {
      overflow-y: auto;
      padding: 0 35px 20px;
    }

    .timesheet-body-info {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      span {
        color: $gray1;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500
      }

      svg {
        fill: $gray6;
        margin-right: 10px;
      }

      &:nth-of-type(2) {
        @include desktop {
          margin-bottom: 20px;
        }
      }
    }

    .modal-section {
      &:last-child {
        margin-bottom: 0;
      }

      h3 {
        color: #4a4a4a;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;

        @include desktop {
          font-size: 18px;
        }
      }

      ul{
        li.timesheet-review-detail {
          border-bottom: 2px $gray8 solid;
          padding: 10px 0 10px 0;

          &:last-child {
            border-bottom: none;
          }

          &:first-child {
            padding: 0 0 10px 0;
          }

          .detail-title {
            color: $gray4;
          }
        }
      }

      @include desktop {
        flex-shrink: 0;
        border-radius: 3px;
        background-color: #fff;
        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.08);
        padding: 20px 25px;
      }

      .input-line {
        display: flex;
        align-items: center;
        margin: 0 -5px 10px -5px;

        .input-box {
          flex: 1;
          padding: 0 5px;
        }

        @include mobile {
          &.full-width {
            display: block;

            .input-box {
              margin-bottom: 10px;
            }
          }
        }
      }

      .add-break,
      .delete-break,
      .delete-expense {
        cursor: pointer;
        display: inline-block;
        margin-left: 10px;
        padding: 0 5px;
        text-align: center;
      }

      .delete-break,
      .delete-expense {
        svg {
          stroke: $red;
          stroke-width: 3;
        }
      }

      .add-break {
        svg {
          fill: $green1;
        }
      }

      .section-header {
        display: flex;
        justify-content: space-between;

        .add {
          color: $blue1;
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
          line-height: 27px;
          position: relative;

          span {
            border: 2px solid $blue1;
            border-radius: 50%;
            color: $blue1;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: bold;
            margin-left: 4px;
            height: 16px;
            width: 16px;
          }

          .expenses-dropdown {
            border: 1px solid #dee2e3;
            border-radius: 3px;
            background-color: #fff;
            box-shadow: 0 3px 4px 0 rgba(0,0,0,0.05), 0 2px 8px 0 rgba(0,0,0,0.06);
            color: #333;
            font-size: 14px;
            font-weight: 500;
            line-height: 26px;
            position: absolute;
            right: 0;
            margin-top: 8px;
            padding: 8px 15px;
            width: 186px;
            z-index: 1000;
          }
        }
      }

      .breaks-acknowledge {
        padding-top: 10px;
      }

      .timesheet-paid-time {
        display: flex;
        justify-content: space-between;
        flex: 1 0 auto;

        h3 {
          margin-bottom: 0
        }

        h4 {
          color: #559955;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 0
        }
      }

      .timesheet-extra-pay {
        color: $gray5;
        font-size: 14px;
        font-weight: 500;
        margin: 15px 0;

        @include desktop {
          margin-top: 0;
        }
      }

      .expenses {
        .section-header {
          h3 {
            margin-bottom: 0
          }
        }
      }

      .team-lead {
        @include mobile {
          .input-line {
            display: block;
            .input-box {
              margin-bottom: 15px;
            }
          }
        }
      }

      .rejection-reason {
        padding: 15px 0;

        p {
          margin-bottom: 20px;
          line-height: 24px;
          text-align: center;

          &:last-child {
            margin-bottom: 0;
          }

          ul {
            margin-top: 5px;
          }
        }
      }
    }
  }

  .timesheet-signature {
    padding-top: 0;

    @include desktop {
      background-color: $gray9;
      box-shadow: none;
    }

    .supervisor-signature-header {
      align-items: center;
      color: $gray6;
      display: flex;
      font-size: 14px;
      justify-content: space-between;

      .clear-signature {
        align-items: center;
        color: red;
        cursor: pointer;
        display: flex;
        font-weight: bold;
        line-height: 0;

        svg {
          stroke: red;
          stroke-width: 3;
        }
      }
    }

    .supervisor-signature-wrapper {
      background-color: white;
      height: 40vh;

      @media (orientation: portrait) {
        height: 50vh;
      }

      @include desktop {
        height: 60vh;
      }
    }

    .sign-above {
      display: flex;
      margin-top: -20px;
      position: relative;
      z-index: 99;

      @include desktop {
        padding: 0 15px;
        margin-top: -40px;
      }

      .signature-line {
        border-bottom: 2px solid black;
        margin-left: 5px;
        width: 100%;
      }

      svg {
        stroke: black;
        stroke-width: 2;
      }
    }
  }

  .modal-content {
    width: 90%;

    .review-modal {
      padding: 20px;

      .modal-header {
        font-size: 20px;
        font-weight: bold;
        margin: 0 0 20px 0;
        text-align: center;
      }

      .modal-body{
        text-align: center;

        .main {
          margin-bottom: 20px;
        }

        button {
          display: block;
          margin: 10px 0;
          padding: 12px 10px;
          width: 100%;
        }

        a {
          color: $gray4;
          margin-bottom: 20px;
          text-decoration: underline;
        }
      }

      a.modal-close {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
}
