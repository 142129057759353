.notification-message-wrapper {
  align-items: center;

  background-color: white;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  padding: 15px;
  margin: 6px 0 40px;

  svg {
    fill: #f0cbcf;
    flex: 0 0 24px;
  }

  .notification-content {
    display: flex;
    flex-direction: row;
    p {
      margin-right: 10px;
    }

    .btn-green {
      width: 100px;
    }
  }


  .notification-text-wrapper {
    margin: 10px;

    h4 {
      color: white;
    }

    .notification-message {
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      line-height: 20px;


      .redirect-link {
        color: #f9ff9a;
        text-decoration: underline;
      }
    }
  }
}
