@import "src/ui-kit/_variables.scss";

.info-message {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: rgba(51,51,51,0.92);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1), 0 4px 10px 0 rgba(0,0,0,0.06);
  padding: 20px;
  flex: 1 0 auto;

  svg.info {
    flex: 0 0 24px;
    fill: $gray4
  }

  p {
    color: white;
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
    margin: 0 16px;
  }
}
