@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.schedule-day {
  .day-slot {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 11px 26px 10px 26px;

    &:not(:last-child) {
      border-bottom: 1px solid #DEE2E3;
    }

    .slot-address {
      font-size: 15px;
      font-weight: bold;
      line-height: 20px;
    }

    .slot-detail {
      display: flex;
      align-items: center;

      &-left {
        flex-grow: 1;
      }

      .badge-osc {
        color: $green2;
        font-weight: bold;
        border: 2px solid $green2;
        border-radius: 2px;
        text-transform: uppercase;
        padding: 0 5px;
        font-size: 11px;
        letter-spacing: .03125rem;
      }
    }

    .slot-title, .slot-times {
      font-size: 13px;
      font-weight: 500;
    }

    &.active {
      @include desktop {
        background-color: #fff;
        z-index: 300;
      }
    }
  }

  &.today {
    .day-slot {
      background-color: $blue5;
      padding: 17px 26px;

      &:not(:last-child) {
        border-bottom: 2px solid $blue1;
      }

      .slot-address {
        color: $gray1;
        font-size: 18px;

        @include mobile {
          font-size: 15px;
        }
      }
      .slot-title, .slot-times {
        color: $gray4;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;

        @include mobile {
          font-size: 13px;
          line-height: normal;
        }
      }
    }
  }
}
