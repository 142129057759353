@import "src/ui-kit/_variables.scss";

.tasks-dropdown {
  margin-top: 10px;
  right: -14px;
  width: 400px;

  &:before, &:after {
    left: auto;
    right: 20px;
  }

  &.dd-menu-items {
    &:before,
    &:after {
      left: auto
    }
  }

  .tasks-dropdown-list {
    li {
      border-bottom: 1px solid $border-gray;
      padding: 12px 15px;

      .task-title {
        color: $black-light;
        font-size: 14px;
        font-weight: bold;
        line-height: 26px;
        margin-right: 10px
      }

      .task-date {
        color: $light-gray;
        font-size: 12px;
        font-weight: 500;
      }

      &.expired {
        .task-title, .task-date {
          color: #b6bdbf;
        }
      }
    }
  }

  .tasks-footer {
    padding: 16px 0;
    text-align: center;
    a {
      display: inline-block
    }
  }
}
