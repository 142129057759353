@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.app-login-footer {
  position: relative;
  bottom: 0;
  .links {
    @include not-mobile {
      text-align: center;
      margin: 0;
    }
  }
}
