@import "src/ui-kit/_variables.scss";

.browser-not-supported {
  background: $gray8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .logo {
    margin-bottom: 20px;
  }

  h3 {
    margin-bottom: 20px;
  }

  ul {
    li {
      font-size: 18px;
      margin-bottom: 15px;

      a {
        display: flex;
        align-items: center;
        font-weight: 500;
      }

      img {
        margin-right: 10px;
      }
    }
  }
}
