@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.expandable-section {
  &.cancellation {
    padding: 0;
  }

  @include desktop {
    &.cancellation {
      border: 0;
      border-radius: 3px;
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.08);
      padding: 0 1.875rem;
    }
  }

  .expandable-header {
    display: flex;
    cursor: pointer;
  }

  h5 {
    display: block;
    cursor: pointer;
    margin-right: auto;
  }

  a {
    color: $blue1;
    font-size: 14px;
    font-weight: 500;
    max-height: 100%;

    @include desktop {
      font-size: 16px;
    }

    span {
      margin-right: 8px
    }

    svg {
      stroke: $gray6;
      transition: transform 0.2s;
    }

    &.rotated {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .inner {
    padding: 5px 0;
    p { 
      font-size: 15px;
      &.penalty-total {
        text-align: center;
        font-size: 1.2rem;
        margin: 20px 10px 10px;
      }
    
    }
    .content-container {
      ul {
        list-style-type: disc;
        // padding-left: 20px;
      }
      li {
        overflow-wrap: break-word;
        @include mobile-and-tablet {
          margin-top: 10px;
        }
      }
      a {
        float: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .cancellation {
    ul {
      padding-left: 0;
    }
  }
}
