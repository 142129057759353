@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.timesheet-header {
  padding: 15px 15px 10px 15px;
  width: 95%;


  @include desktop {
    padding: 35px 0 10px 0;
  }

  &-title {
    h2 {
      color: $blue1;
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      margin-bottom: 0;

      @include desktop {
        font-size: 28px;
      }
    }

    .job-id,
    .timesheet-header-subtitle {
      color: $gray6;
      font-size: 14px;
      font-weight: 500
    }
  }

  &-back {
    align-items: baseline;
    border-bottom: 2px $gray8 solid;
    color: $blue1;
    display: flex;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin: 0 0 15px 0;
    padding: 5px 0;
    width: 100%;

    @include desktop {
      font-size: 20px;
    }

    svg {
      stroke: $blue1;
    }
  }

  &-signature-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 25px;
    position: absolute;
    text-align: center;
    top: 25px;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include  desktop {
      font-size: 20px;
      line-height: 30px;
      top: 25px;
    }

    @media screen and (max-width: 400px) {
      width: 180px;
      margin-left: 10px;
    }

    @media screen and (min-width: 401px) {
      width: 250px;
    }
  }

  .timesheet-signature-header {
    width: auto;
    display: flex;
    justify-content: center;
  }

  .timesheet-info-status {
    margin-bottom: 10px;
  }
}
