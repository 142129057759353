@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.job-detail-summary {
  padding-left: 0;
  padding-right: 0;

  .icon-label {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: $gray5;
      font-size: 13px;
      font-weight: 500;
      line-height: 15px;
    }

    svg {
      fill: $gray6;
      margin-right: 5px;
      vertical-align: middle
    }
  }

  .summary-top {
    display: flex;
    padding-bottom: 20px;

    &>div {
      flex: 1;
      text-align: center;

      &:nth-child(2) {
        border-left: 1px solid $gray8;
        border-right: 1px solid $gray8;
      }
    }

    .icon-label {
      padding-bottom: 1px;
    }
  }

  .job-date-range {
    .vertical-date {
      margin-bottom: 5px;
      .month { font-size: 13px }
      .day {
        font-size: 22px;
        line-height: 27px;

        @include desktop {
          font-size: 28px;
          line-height: 34px;
        }
      }
    }

    svg.arrow-right {
      margin: 0 5px;
      position: relative;
      bottom: 4px;
      stroke: $gray8;

      @include desktop {
        margin: 0 10px;
      }
    }
  }

  .job-time-range {
    .times {
      color: $gray4;
      font-size: 13px;
      font-weight: 500;

      @include mobile {
        font-size: 10px;
        line-height: 15.6px;
      }
    }

    .total-hours {
      font-size: 22px;
      font-weight: 500;
      line-height: 27px;
      margin-bottom: 5px;

      @include desktop {
        font-size: 28px;
        line-height: 34px;
      }
    }
  }

  .job-payment {
    .per-hour {
      color: $gray4;
      font-size: 13px;
      font-weight: 500;
    }
    .per-hour-value {
      color: $green2;
      font-size: 22px;
      font-weight: 500;
      line-height: 27px;
      margin-bottom: 5px;

      @include desktop {
        font-size: 28px;
        line-height: 34px;
      }
    }
  }

  .summary-bottom {
    display: flex;
    border-top: 1px solid $gray8;
    padding-top: 20px;

    &>div {
      flex: 1;
      text-align: center;

      &.travel-incentive {
        border-left: 1px solid $gray8;
        padding: 0 12px;
      }
    }

    .section-value {
      font-weight: 500;
      margin-bottom: 5px;

      @include mobile {
        font-size: 13px;
      }
    }
  }
}
