@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.modal-section {
  padding: 20px 0;
  width: 100%;

  &.cancellation {
    padding: 0;
  }

  .cancellation-header {
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 1.875rem;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: $gray4;
    color: white;
    a {
      color: $blue1;
      float: right;
      font-size: 0.875rem;
      font-weight: 500;
      max-height: 100%;
  
      @include desktop {
        font-size: 1rem;
      }
  
      span {
        margin-right: 0.5rem
      }
  
      svg {
        stroke: white;
        transition: transform 0.2s;
      }
  
      &.rotated {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  &:last-child {
    margin-bottom: 110px;
  }

  @include desktop {
    background-color: white;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.08);
    margin-bottom: 20px;
    padding: 20px 30px;
    &.cancellation {
      padding: 0;
    }
  }

  h5 {
    font-size: 16px;

    @include desktop {
      font-size: 18px;
    }
  }

  .section-header {
    
    @include desktop {
      margin-bottom: 20px;
    }

    &.no-margin {
      margin-bottom: 0
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    .warning {
      color: #ed4d5d;
      font-size: 15px;
    }

    .conflict-message {
      display: flex;
      align-items: center;
    }

    span {
      color: #ed4d5d;
      font-size: 14px;
      font-weight: bold;
    }
    .block {
      display: inline-block;
      border: 3px solid #ed4d5d;
      border-radius: 2px;
      height: 12px;
      width: 12px;
      margin-right: 8px;
    }
  }

  hr {
    height: 1px;
    border: 0;
    border-top: 1px solid $gray7;
    margin: 20px 0;
  }
}
