h1{
  font-size: 40px;
  line-height: 50px;
  font-weight: bold;

  @include mobile{
    font-size: 28px;
    line-height: 34px;
  }
}

h2, .h2{
  font-size: 30px;
  line-height: 37px;
  font-weight: bold;
  margin-bottom: 25px;

  @include tablet{
    font-size: 22px;
    line-height: 27px;
  }

  @include mobile{
    font-size: 20px;
    line-height: 22px;
  }
}

.h2 { display: inline-block }

h3{
  font-size: 22px;
  line-height: 27px;
  font-weight: bold;
}

h4{
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  margin-bottom: 8px
}

em { font-style: italic }
