@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.timesheet-modal {
  .side-modal {
    .side-modal-content.open {
      overflow: visible;

      @include mobile-and-tablet {
        overflow: auto;
      }

      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
        overflow: auto;
      }

      .side-modal-body {
        padding: 0;
        overflow-y: visible;

        .anchor {
          display: block;
          height: 0;
          position: relative;
          top: -250px;
          visibility: hidden;
        }

        .loading {
          padding: 15px 15px 10px 15px;

          @include desktop {
            padding: 35px 0 15px 0;
          }
        }
      }
    }
  }

  .signature-removal-modal {
    .modal-header {
      padding: 30px 0;

      h3 {
        text-align: center;
      }

      .close {
        position: absolute;
        top: 20px;
        right: 25px;
        z-index: 999;

        svg {
          stroke: $gray4;
          width: 20px;
          height: 20px;
          vertical-align: middle
        }
      }
    }

    .modal-body {
      padding: 0 25px 15px;
      text-align: center;

      p {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 30px;
      }

      .confirm-actions {
        &>.btn {
          padding-left: 10px !important;
          padding-right: 10px !important;
          margin-bottom: 15px;
        }

        .continue-button {
          padding: 15px 10px;
        }

        @include desktop {
          display: flex;
          justify-content: center;

          &>.btn {
            margin: 0 10px 15px;
            flex: 1;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
