@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.schedule-day {
  display: flex;
  flex-direction: column;
  color: $gray4;
  font-size: 13px;
  margin-bottom: 30px;
  border-radius: 4px 4px 0 0;

  .day-header {
    background-color: $gray9;
    border-radius: 4px 4px 0 0;
    font-weight: bold;
    letter-spacing: 1.2px;
    line-height: 16px;
    padding: 8px 26px;
    text-transform: uppercase;

    .header-right {
      float: right;
      text-align: right;
    }
  }

  .day-message {
    padding: 11px 26px;
  }

  &.today {
    border: 2px solid $blue1;

    .day-header {
      background-color: $blue1;
      border-radius: 0;
      color: white;
      font-size: 20px;
      line-height: 25px;
      text-transform: none;
      .header-right {
        font-size: 16px;
        text-transform: uppercase;
      }

      @include mobile {
        font-size: 13px;
        line-height: 16px;
        text-transform: uppercase;
        .header-right {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }

    .day-message {
      background-color: $blue5;
      color: $gray1;
      font-size: 18px;
      font-weight: bold;
      line-height: 20px;
      padding: 17px 26px;
    }

    @include mobile {
      border-left: 0;
      border-right: 0;
    }
  }
}
