@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.referrals-content {
  padding: 45px 0;

  .h3 {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 6px;
    margin-right: 8px;
  }

  .tooltip-wrapper {
    position: relative;
    top: 1px;
    vertical-align: initial;
  }

  p {
    color: $black-light;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 25px;
  }

  .referrals-callout {
    background-color: #f4F9ff;
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 20px;
    text-align: center;
    @include mobile {
      display: none
    }

    &-title {
      color: $black-light;
      font-size: 17px;
      font-weight: bold;
      line-height: 21px;
      text-align: center;
      text-transform: uppercase;
    }

    &-amount {
      color: $blue1;
      font-size: 72px;
      font-weight: bold;
      line-height: 48px;
      text-align: center;
      margin-bottom: 20px;
      &:before {
        content: "$";
        font-size: 28px;
        font-weight: bold;
        vertical-align: super;
      }
    }

    &-details {
      color: #5984c0;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      text-align: center;
    }
  }

  .form-action {
    margin-top: 50px;

    @include mobile {
      .btn {
        width: 100%
      }
    }
  }

  .referral-list {
    border-top: 1px solid $border-gray;
    margin-top: 55px;
  }
}
