@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.file-item {
  margin-right: 5px;
  margin-top: 6px;
  overflow: hidden;

  .badge {
    background-color: $blue5;
    border: 1px solid #cddcef;
    border-radius: 5px;
    color: $blue1;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 9px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 5px;
      fill: $blue1;
      stroke: unset;
      width: 12px;
      height: 12px;
    }

    span {
      margin-right: 0;
    }
  }
}