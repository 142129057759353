@import "src/ui-kit/_variables.scss";

.talentAgentInfo {
  background-color: white;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  margin: 10px 5px;
  text-align: center;
  &__title {
    margin-top: 10px;
    font-size: 12px;
    color: $light-gray;
    letter-spacing: 1px;
    line-height: 1.66;
    text-transform: uppercase;
  }
  &__data {
    padding: 12px;
    &--item{
      color: #333333;
      font-size: 18px;
      line-height: 1.25;
      &.name{
        font-weight: 600;
      }
    }
  }
}