@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.authentication {
  .form-section {
    background-color: white;
    border: 1px solid #Dee2e3;
    border-top: 8px solid $blue1;
    border-radius: 4px;
    margin-bottom: 35px;
    padding: 30px 25px 45px 25px;
    text-align: center;

    @include desktop {
      padding: 30px 35px 45px 35px;
    }

    h2 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
    }

    .form {
      margin-top: 10px
    }

    fieldset {
      margin-bottom: 15px
    }

    .forgot-password {
      margin-bottom: 25px;
      a {
        color: $black-light;
        font-weight: 500;
        text-decoration: underline;
      }
    }

    .back-to-login {
      margin-top: 25px;
      a {
        color: $light-gray;
        font-weight: bold;
      }
      img {
        margin-right: 8px
      }
    }
  }

  .apply-now {
    color: #82898B;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .login-form-warning {
    margin-bottom: 50px;
    text-align: left;
  }

  .logo-container {
    img {
      max-width: 80%;
      padding-bottom: 50px;
    }
  }
}
