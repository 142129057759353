.employment-information {
  .modal-body {
    text-align: center;
    width: 100%;
  }

  .modal-footer {
    padding-bottom: 15px;

    &.payroll {
      text-align: center;
    }

    p {
      font-size: 14px;
      font-weight: 300;
      line-height: 26px;
      margin-top: 20px;
    }
  }
}
