@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.dollar-amount {
  color: $green2;
  font-size: 13px;
  font-weight: 500;

  &:before {
    content: '$';

    @include desktop {
      font-size: 11px;
      vertical-align: super;
    }
  }

  @include desktop {
    font-size: 23px;
    vertical-align: middle;
  }

  &-cents {
    @include desktop {
      border-bottom: 1px solid #dcf2db;
      font-size: 11px;
      margin-left: 1px;
      vertical-align: super;
    }
    &:before {
      @include mobile {
        content: '.'
      }
      @include tablet {
        content: '.'
      }
    }
  }
}
