.error-message-wrapper {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: rgba(237,77,93,0.96);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1), 0 4px 10px 0 rgba(0,0,0,0.06);
  padding: 15px 20px;

  svg {
    fill: #f0cbcf;
    flex: 0 0 24px;
  }

  .error-message {
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    margin: 0 0 0 16px;

    .redirect-link {
      color: white;
      text-decoration: underline;
    }
  }
}
