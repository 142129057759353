@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.employment-info-header {
  margin-bottom: 25px;
  padding: 15px 15px 10px 15px;
  width: 95%;


  @include desktop {
    padding: 35px 0 10px 0;
  }

  .employment-info-title {
    h2 {
      color: $blue1;
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      margin-bottom: 0;

      @include desktop {
        font-size: 28px;
      }
    }

    .employment-info-subtitle {
      color: $gray6;
      font-size: 14px;
      font-weight: 500
    }
  }

}
