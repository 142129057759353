@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.assignments-content {
  .container {
    margin: 0 auto;
    max-width: 640px;

    .error-message-wrapper {
      margin-bottom: 40px;
    }
  }

  .loading {
    &.small {
      transform: scale(.4);
      padding: 0;
    }
  }

  h2 {
    font-size: 28px;
    margin-bottom: 25px;
    text-align: center;
  }

  @include mobile {
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
  }
}
