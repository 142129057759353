@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.app-footer {
  color: $light-gray;
  width: 100%;
  height: 70px;
  clear: both;
  margin: 30px 0 ;
  position: relative;
  z-index: 2;
  @media (min-width: $desktop-medium-width+1) and (max-width: $desktop-wide-width) {
    .container {
      padding: 0 90px;
    }
  }
  @media (min-width: $tablet-wide-width+2) and (max-width: $desktop-medium-width) {
    .container {
      padding: 0 60px;
    }
  }
  .inner {
    border-top: 2px solid $border-gray;
    padding: 22px 0;
  }

  .logo {
    text-align: center;
    @include not-mobile {
      text-align: left;
    }
    svg {
      fill: $gray5;
    }
  }

  .links {
    margin: 15px 0;
    text-align: center;

    @include not-mobile {
      text-align: right;
      margin: 0;
    }

    li {
      display: inline-block;
      margin: 0 15px;

      @include not-mobile {
        margin-left: 20px;
        margin-right: 0;
      }

      a {
        color: inherit;
        font-weight: 500;

        &.bold-link {
          font-weight: 900;
        }
      }

      &.separator {
        border-right: 2px solid $border-gray;
        margin-right: 0;
        padding-right: 15px;

        @include not-mobile {
          padding-right: 20px;
        }
      }
    }
  }

  .copyright,
  .app-version {
    text-align: center;
    @include desktop-wide{
      text-align: left;
    }
    p {
      font-size: 14px;
    }
  }

  .app-version {
    margin-top: 1px;
  }

}
