@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.confirm-agreements-modal {
  .modal-header {
    padding: 30px 0;
    text-align: center;

    h3 {
      width: 68%;
      margin: 0 auto;
      text-align: center; 
      @include mobile {
        font-size: 1.2rem
      }
    }
    .modal-close {
      float: right;
    }
    .close {
      position: absolute;
      top: 30px;
      right: 25px;
      z-index: 999;
      svg {
        stroke: $gray4;
        width: 20px;
        height: 20px;
        vertical-align: middle
      }
    }
    .close-modal {
      position: absolute;
      top: 30px;
      right: 25px;
      z-index: 999;
      svg {
        stroke: $gray4;
        width: 20px;
        height: 20px;
        vertical-align: middle
      }
    }
  }

  .modal-body {
    padding: 0 25px 15px;
    text-align: center;

    .form-action {
      margin-top: 35px;

      @include mobile {
        .btn {
          width: 100%
        }
      }
    }

    p {
      font-size: 1.1rem;
      font-weight: 500;
      margin-bottom: 30px;
    }

    .agreements {
      padding: 15px;
      max-height: 50vh;
      overflow: auto;
    }
  
    .confirm-actions {
      &>.btn {
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-bottom: 15px;
      }

      @include desktop {
        display: flex;
        justify-content: center;

        &>.btn {
          margin: 0 10px 15px;
          flex: 1;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
   }

  }
}
