@import "src/ui-kit/_variables.scss";

.vertical-date {
  display: inline-block;
  text-align: center;
  .month {
    display: block;
    color: $gray4;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .day {
    color: $black-light;
    font-size: 23px;
    font-weight: bold;
    line-height: 29px;
  }
}
