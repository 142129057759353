@import "src/ui-kit/_variables.scss";
@import "src/ui-kit/_mixins.scss";

.content {
  margin-top: 60px;
  padding: 20px 0 45px 0;



  @include desktop {
    padding: 45px 20px;
    margin-left: calc(100vw - 100%);
    margin-right: 0;
  }

  .container {
    @include mobile {
      .row {
        margin-left: 0;
        margin-right: 0;

        &> [class^="col-"] {
          min-height: auto;
          padding-left: 0;
          padding-right: 0
        }
      }
    }
  }
}
